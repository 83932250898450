import styled from "styled-components"

export const Section = styled.section.attrs({
  className: `${(props) => props.className}`,
})`
  h2 {
    /* margin-bottom: 5rem;
    font-size: 2rem;
    font-weight: 300; */
  }

  @media (max-width: 600px) {
    h2 {
      /* margin-bottom: 3rem;
      font-size: 1.5rem; */
      text-align: left;
    }
  }
`

export const Card = styled.section.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  box-shadow: 0px 0px 40px #142c1f14;
  border-radius: 0.4rem;
  padding: 1.5rem;

  img {
    width: 4rem;
    height: 4rem;
    margin-top: -4rem;
  }

  h6 {
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
    margin-top: 2rem;
    color: #142c1f;
  }

  p {
    font-size: 0.85rem;
    color: #44554c;
  }

  @media (max-width: 991.99px) {
    flex-direction: column;
  }
`

export const Wrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  gap: 50px;
  grid-template-columns: 1fr 1fr;

  img.device {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
  }
`

export const Services = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .service {
    padding: 2rem;

    &:hover {
    }

    &.active {
      background: white;
      box-shadow: 0px 20px 40px #02332a1a;
      border-radius: 20px;
      transform: scale(1.05);
    }

    .icon {
      width: 5.4rem;
      height: 5.4rem;
      border-radius: 50%;
      background: #e5f5f2;
      img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
      }
    }

    h6 {
      /* font-size: 1rem; */
      /* margin: 12px 0 10px;
      color: rgb(20 44 31);
      font-weight: 500; */
    }

    p {
      /* font-size: 1rem;
      line-height: 1.3rem;
      color: #44554c;
      font-weight: 400; */
    }
    .device-small {
      margin-top: 1.5rem;
      display: none;
    }
  }

  @media (max-width: 767.99px) {
    grid-template-columns: 1fr;

    .service.active {
      .device-small {
        /* display: block; */
        width: 100%;
        height: auto;
      }
    }
  }

  /* @media (min-width: 992px) {
    padding-top: 4rem;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 768px) and (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }
 */
`
