import styled from "styled-components"

const Section = styled.section.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
`

const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 50%;
  padding-top: 12rem;
  padding-right: 2rem;
  h3 {
    color: #142c1f;
    /* font-size: 2rem; */
    strong {
      font-weight: 700;
    }
  }

  p {
    /* margin-block: 1.3rem; */
    /* font-size: 1rem; */
    color: #44554c;
  }


  @media (max-width: 991.99px) {
    padding-right: 0;
    width: 100%;
    padding-top: 0;
  }
`

const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 50%;
  img {
    object-fit: contain;
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-top: 4%;
  }

  .video {
    /* position: absolute;
    top: 0;
    left: 50%; */
    /* transform: translateX(-50%); */
    width: 100%;
    height: 100%;
    max-width: 500px;
    > div {
      width: 100% !important;
    height: 100% !important;
      /* height: 500px !important;
      width: 300px !important; */
      margin-inline: auto;
    }
  }

  @media (max-width: 991.99px) {
    width: 100%;
    text-align: center;
    padding-top: 3rem;
  }
`

export { Section, TextContent, VisualContent }
