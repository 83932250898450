import styled from "styled-components"

const FiltersNavbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  justify-content: space-between;
  gap: 2rem;
  padding-block: 1rem;
  margin-top: 6rem;

  @media (max-width: 767.99px) {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 0.5rem;
    .left {
      align-self: flex-start;
    }
  }
`

export { FiltersNavbar }
