import styled from "styled-components"

export const Navbar = styled.nav`
  padding: 10px;
  background-color: #199c86;
  border-bottom: 2px solid white;
  @media (max-width: 1024px) {
    display: none;
  }
`

export const Links = styled.ul`
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
  width: fit-content;

  li {
    padding: 0 1rem;
    display: inline-block;
    a {
      color: white;
      text-decoration: none;
      font-weight: 700;
      font-size: 15px;

      img {
        margin-bottom: -6px;
        margin-right: 4px;
      }
    }
    &:not(:last-child)::after {
      width: auto;
      content: "";
      position: relative;
      height: 100%;
      border-left: 1px solid #d2d2d2;
      right: -16px;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const MenuIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 20px;
  top: 23px;

  @media (min-width: 601px) {
    display: none;
  }
`
