import styled from "styled-components"

const Section = styled.section.attrs({
  className: `${(props) => props.className}`,
})`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left;
  margin-block: 3rem;
  display: flex;
  gap: 2rem;
  position: relative;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }
`

const Wrapper = styled.section.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  gap: 2rem;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }
`

const Background = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -o-available;
  height: 550px;
  position: absolute;
  top: -2rem;
  z-index: -1;

  @media (max-width: 991.99px) {
    height: 24rem;
    top: -2rem;
  }
`

const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
    min-width: 300px;
  > div {
    height: 500px !important;
    width: 300px !important;
  }
  video,
  img {
    object-fit: cover;
    border-radius: 8px;
  }

  img {
    width: 15rem;
    height: 20rem;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
  }
`

const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding-top: 1.5rem;
  h3 {
    color: #142c1f;
    font-size: 2rem;
    strong {
      font-weight: 700;
    }
  }

  p {
    margin-block: 3rem;
    font-size: 1rem;
    color: #44554c;
  }

  button {
    width: 200px;
  }

  @media (max-width: 991.99px) {
    width: 100%;
    button {
      display: block;
      margin-inline: auto;
    }
  }
`

export { Section, TextContent, VisualContent, Background, Wrapper }
