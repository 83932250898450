import styled from "styled-components"

export const SectionContainer = styled.div`
  background: #EEEEEE;
  margin-top: 130px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  align-items: center;
  padding: 10rem 0rem;

  .right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    > * {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    padding: 20px 10px;

    .right-side {
      width: 100%;
      justify-content: center;
      width: 100%;
    }
  }
`
