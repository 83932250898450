import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  ArrowRightCircle,
  ArrowLeftCircle,
  X,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";
import Header from "components/HeaderExisting/Header";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import Navbar from "components/StickyNavbar/Navbar";
import Footer from "components/BlackFooter/Footer";
import UserSlider from "components/UserSlider/UserSlider";
import CouchCard from "components/CouchCard/CouchCard";
import CouchFilters from "components/CouchFilters/CouchFilters";
import AppointmentSection from "components/AppointmentSection/AppointmentSection";
import ClientsTestimonials from "components/ClientsTestimonials/ClientsTestimonials";
import ScrollIcon from "assets/images/scroll.svg";
import * as C from "constants";
import SectionWrapper from "components/sectionWrapper/SectionWrapper";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import SliderCoachCard from "components/SliderCoachCard/SliderCoachCard";
import CoachSideFilters from "components/CoachSideFilters/CoachSideFilters";
import StartNutrition from "components/StartNutrition/StartNutrition";
import PromotedCoaches from "components/PromotedCoaches/PromotedCoaches";

const IMG_BASE_URL = C.IMG_BASE_URL;

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [promotedCouchList, setPromotedCouchList] = useState(null);
  const [couchList, setCouchList] = useState(null);
  const [isCouchListFetching, setIsCouchListFetching] = useState(false);
  const [couchListActivePage, setCouchListActivePage] = useState("");

  const [openLeadModal, setOpenLeadModal] = useState(false);

  const totalPages = Math.ceil(couchList?.total / couchList?.per_page) + 2;

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    getPromotedCouches();
    getCoaches();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [couchList]);

  const getPromotedCouches = async () => {
    try {
      const res = await axios.get(`${C.BASE_URL}promoted-coach/list`);
      setPromotedCouchList(res?.data?.promotedCoachList);
    } catch (e) {
      console.log(e);
    }
  };

  const getCoaches = async (
    pageUrl = "",
    filters = {
      // sex: ["male", "female"],
      // subtitle: ["Abnehmen"],
      // step1: ["Athletiktraining", "Bodybuilding"],
      // step2: ["0-2", "2-7", ">7"],
      // age: ["18-25", "26-30", ">40"],
      // newCoachData: 1,

      sex: [],
      subtitle: [],
      step1: [],
      step2: [],
      age: [],
      newCoachData: 0,
    }
  ) => {
    const URL = pageUrl || `${C.BASE_URL}coach/filter?page=1`;
    setIsCouchListFetching(true);
    try {
      const res = await axios.post(URL, filters, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": C.API_KEY_PRODUCTION,
        },
      });
      console.log("Post Coaches-------------------", res?.data);
      setCouchList(res?.data?.coachFilterData);
      setIsCouchListFetching(false);
      // setTimeout(() => {}, 300)
      // console.log("couchListActivePage", couchListActivePage)
      setCouchListActivePage(URL);
    } catch (e) {
      setIsCouchListFetching(false);
      console.log(e);
    }
  };

  const paginationItems = () => {
    let pages = Array.from({ length: totalPages }, (_, idx) => idx);
    return pages.map((page, i) => {
      if (i === 0 && couchList?.prev_page_url) {
        return (
          <li className="page-item" key={page}>
            <ChevronLeft
              strokeWidth={2}
              onClick={() => {
                getCoaches(`${couchList?.prev_page_url}`);
                setCouchListActivePage(couchList?.prev_page_url);
              }}
            />
          </li>
        );
      }
      if (i === pages.length - 1 && couchList?.next_page_url) {
        return (
          <li className="page-item" key={page}>
            <ChevronRight
              strokeWidth={2}
              onClick={() => {
                getCoaches(`${couchList?.next_page_url}`);
                setCouchListActivePage(couchList?.next_page_url);
              }}
            />
          </li>
        );
      }
      if (i !== 0 && i !== pages.length - 1) {
        return (
          <li className="page-item" key={page}>
            <span
              className={
                `${couchList?.path}?page=${page}` === couchListActivePage
                  ? "page-link active-page"
                  : "page-link"
              }
              onClick={() => {
                getCoaches(`${couchList?.path}?page=${page}`);
                setCouchListActivePage(`${couchList?.path}?page=${page}`);
              }}
            >
              {page}
            </span>
          </li>
        );
      }
    });
  };

  const handleAllFilters = (filters) => {
    console.log("filters", filters);

    const alters = filters.selectedAlterFilter
      .filter((item) => item?.checked)
      .map((item) => {
        if (item.name.includes("Uber")) {
          return {
            ...item,
            name: `>40`,
          };
        }

        return {
          ...item,
          name: `${item.name.split(" ")[0]}${item.name.split(" ")[1]}${
            item.name.split(" ")[2]
          }`,
        };
      });

    const experiences = filters.selectedExperienceFilter
      .filter((item) => item?.checked)
      .map((item) => {
        if (item.name.includes(">")) {
          return {
            ...item,
            name: `>7`,
          };
        }

        return {
          ...item,
          name: `${item.name.split(" ")[0]}${item.name.split(" ")[1]}${
            item.name.split(" ")[2]
          }`,
        };
      });

    getCoaches("", {
      sex: [],
      subtitle:
        filters?.selectedObjectiveFilters
          .filter((item) => item?.checked)
          .map((item) => item?.name) || [],
      step1:
        filters?.selectedSpecialityFilter
          .filter((item) => item?.checked)
          .map((item) => item?.name) || [],
      step3:
        filters?.selectedNoOfTrainingsFilter
          .filter((item) => item?.checked)
          .map((item) => item?.name?.split(" ")[0]) || [],
      step2: experiences.map((item) => item?.name) || [],
      age: alters.map((item) => item?.name) || [],
      // newCoachData: filters?.isNewCoach ? 1 : 0,
    });
  };

  return (
    <>
      <div className="home-page">
        <section className="header-wrap">
          <Navbar onOpenLeadForm={() => setOpenLeadModal(true)} />
          <div className="ct-section-inner-wrap mx-auto h-100 border-box d-flex align-items-center justify-conent-center">
            <div id="div_block-79-31" className="mx-auto mt-16">
              <div className="header text-center">
                <div className="badge badge-white ct-headline color-primary bg-white py-3 px-6 uppercase text-base rounded-full font-medium">
                  STARTEN
                </div>
                <h1
                  id="text_block-5-31"
                  className="ct-text-block font-bold color-dark text-center mt-8 text-6xl"
                >
                  Vereinbare dein <br /> Erstgespräch
                </h1>
                <p className="text-base mt-4">
                  Wähle deinen persönlichen Ernährungsberater, welcher dich bei
                  deiner
                  {!isMobile && <br />} Ernährungsumstellung mit wertvollen
                  Tipps und Tricks unterstützt.
                </p>
              </div>
            </div>
          </div>
          <img className="scroll-icon" src={ScrollIcon} alt="scroll" />
        </section>
        <div className="bg-white">
          <div className="ct-section-inner-wrap mx-auto listings-section">
            {/* <div className="row">
            {couchList?.data.slice(0, 12).map((item) => (
              <div className="col-6">
                <CouchCard
                  isMobile={isMobile}
                  coach={item}
                  key={item?.id}
                  baseUrl={IMG_BASE_URL}
                />
              </div>
            ))}
          </div> */}
            {/* id="div_block-79-31" className="mx-auto" */}
            {/* {promotedCouchList && (
              <UserSlider
                isMobile={isMobile}
                promotedCouchList={promotedCouchList}
                baseUrl={IMG_BASE_URL}
              />
            )} */}
            {/* <CouchFilters /> */}
            <section className="list-with-filters">
              <div className="list-filters">
                <CoachSideFilters handleAllFilters={handleAllFilters} />
              </div>
              <div>
                {isCouchListFetching && (
                  <div className="main-loader-wrapper d-flex align-items-center justify-content-center">
                    <Spinner
                      className="spinner"
                      animation="border"
                      variant="success"
                    />
                  </div>
                )}
                {!isCouchListFetching && (
                  <>
                    <section className="grid-view">
                      {couchList?.data.slice(0, 12).map((item) => (
                        <CouchCard
                          isMobile={isMobile}
                          coach={item}
                          key={item?.id}
                          baseUrl={IMG_BASE_URL}
                        />
                      ))}
                    </section>
                    <section className="promoted-coaches">
                      <div className="coaches">
                        <div className="border-dashed my-4"></div>
                        <section className="slider-with-green-item">
                          {!!promotedCouchList && (
                            <PromotedCoaches
                              promotedCoaches={promotedCouchList}
                              baseUrl={IMG_BASE_URL}
                            />
                          )}
                        </section>
                        <div className="border-dashed my-4"></div>
                      </div>
                    </section>
                    <section className="grid-view mt-4">
                      {couchList?.data.slice(12).map((item) => (
                        <CouchCard
                          isMobile={isMobile}
                          coach={item}
                          key={item?.id}
                          baseUrl={IMG_BASE_URL}
                        />
                      ))}
                    </section>
                  </>
                )}
              </div>
            </section>

            <div className="load-more-action text-center">
              {/* <button className="btn-medium btn-load-more">Mehr laden</button> */}
              <nav className="pagination-nav">
                <ul className="pagination">{couchList && paginationItems()}</ul>
              </nav>
            </div>
          </div>

          <SectionWrapper mobileMargin="70px 0" margin="40px 0">
            <StartNutrition />
          </SectionWrapper>

          <div className="bg-curve">
            <div className="ct-section-inner-wrap mx-auto py-0">
              <div id="div_block-79-31" className="mx-auto">
                {/* <SectionWrapper mobileMargin="0 0 100px" margin="0 0 150px">
                  <AppointmentSection />
                </SectionWrapper> */}
                <SectionWrapper
                  mobileMargin="50px 0 60px"
                  margin="60px 0 100px"
                >
                  <TestimonialSlider />
                  {/* <ClientsTestimonials page="Home" /> */}
                </SectionWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <LeadSurveyModal
        isOpen={openLeadModal}
        onClose={() => setOpenLeadModal(false)}
        coachProfile={null}
      />
    </>
  );
}

export default Home;
