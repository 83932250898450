import styled from "styled-components";

const Section = styled.section`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left;
  margin-block: 3rem;
  display: flex;
  gap: 2rem;
  position: relative;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10rem;
  width: 100%;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }
`;

const Background = styled.div`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -o-available;
  height: 550px;
  position: absolute;
  top: -2rem;
  z-index: -1;

  @media (max-width: 991.99px) {
    height: 24rem;
    top: -2rem;
  }
`;

const IntroVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .video-wrapper {
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 15px;
  }

  .video {
    position: relative;
    width: 400px;
    height: 600px;
    border-radius: 15px;

    .play-btn {
      position: absolute;
      z-index: 2;
      width: 80px;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
      transition: all 200ms ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }

    .react-player {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;

      video {
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }
  
  @media (max-width: 600px) {
    width: 100%;
    .video-wrapper, .video {
      width: 100%;
    }
  }
`;

const TextContent = styled.div`
  padding-top: 1.5rem;
  h3 {
    /* color: #142c1f;
    font-size: 2rem;
    font-weight: 400;
    strong {
      font-weight: 700;
    } */
  }

  p {
    /* margin-block: 3rem;
    font-size: 1rem;
    color: #44554c;
    font-weight: 400; */
  }

  @media (min-width: 992px) {
    min-height: 400px;
  }

  @media (max-width: 991.99px) {
    width: 100%;
    button {
      display: block;
    }
  }

  @media (max-width: 600px) {
    h3 {
      br {
        display: none;
      }
    }
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 991.99px) {
    justify-content: center;
  }
`

const ImageBox = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;

  @media (max-width: 991.99px) {
    width: 100%;
  }
`

const Image = styled.div`
  width: 400px;
  height: 500px;
  background: url('${props => props.image}') no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 10px;

  @media (max-width: 991.99px) {
    width: auto;
  }
`


export { Section, TextContent, IntroVideo, Background, Wrapper, ImagePreview, Image, ImageBox };
