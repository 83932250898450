import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import DisplaySelectedFilters from "components/DisplaySelectedFilters/DisplaySelectedFilters";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Filter, ChevronDown } from "react-feather";

const CoachSideFilters = ({ handleAllFilters }) => {
  // Objective Filters
  const [looseWeightCheckBox, setLooseWeightCheckBox] = useState(false);
  const [healtyEatingCheckBox, setHealtyEatingCheckBox] = useState(false);
  const [leanMuscleCheckBox, setLeanMuscleCheckBox] = useState(false);
  const [bulkMuscleCheckBox, setBulkMuscleCheckBox] = useState(false);

  // Speciality Filters
  const [bodyBuildingCheckBox, setBodyBuildingCheckBox] = useState(false);
  const [fitnessNutritionCheckBox, setFitnessNutritionCheckBox] =
    useState(false);
  const [balancedNutritionCheckBox, setBalancedNutritionCheckBox] =
    useState(false);
  const [competitiveSupportCheckBox, setCompetitiveSportCheckBox] =
    useState(false);
  const [veganDietCheckBox, setVeganDietCheckBox] = useState(false);
  const [ VegetarischeErnährungCheckbox, setVegetarischeErnährungCheckbox ] = useState(false)
  const [ HormonbalanceCheckbox, setHormonbalanceCheckbox ] = useState(false)
  const [ ErnährungfürSeniorenCheckbox, setErnährungfürSeniorenCheckbox ] = useState(false)
  const [ KinderundJugendernährungCheckbox, setKinderundJugendernährungCheckbox ] = useState(false)
  const [ AdipositasManagementCheckbox, setAdipositasManagementCheckbox ] = useState(false)
  const [ GewichtsmanagementbeiFrauenCheckbox, setGewichtsmanagementbeiFrauenCheckbox ] = useState(false)
  const [ GewichtsmanagementbeiMännernCheckbox, setGewichtsmanagementbeiMännernCheckbox ] = useState(false)
  const [ DarmgesundheitCheckbox, setDarmgesundheitCheckbox ] = useState(false)
  const [ ErnährunginderSchwangerschaftCheckbox, setErnährunginderSchwangerschaftCheckbox ] = useState(false)
  const [ ErnährunginderMenopauseCheckbox, setErnährunginderMenopauseCheckbox ] = useState(false)

  // No Of Trainings Filters
  const [trainingOneCheckBox, setTrainingOneCheckBox] = useState(false);
  const [trainingTwoCheckBox, setTrainingTwoCheckBox] = useState(false);
  const [trainingThreeCheckBox, setTrainingThreeCheckBox] = useState(false);
  const [trainingFourCheckBox, setTrainingFourCheckBox] = useState(false);
  const [trainingMoreThanFourCheckBox, setTrainingMoreThanFourCheckBox] =
    useState(false);

  // Experience Filters
  const [experienceYoungCheckBox, setExperienceYoungCheckBox] = useState(false);
  const [experienceOneCheckBox, setExperienceOneCheckBox] = useState(false);
  const [experienceTwoCheckBox, setExperienceTwoCheckBox] = useState(false);
  const [experienceThreeCheckBox, setExperienceThreeCheckBox] = useState(false);
  const [experienceFourCheckBox, setExperienceFourCheckBox] = useState(false);
  const [experienceFiveCheckBox, setExperienceFiveCheckBox] = useState(false);
  const [experienceSixCheckBox, setExperienceSixCheckBox] = useState(false);

  // Age Filters
  const [ageOneCheckBox, setAgeOneCheckBox] = useState(false);
  const [ageTwoCheckBox, setAgeTwoCheckBox] = useState(false);
  const [ageThreeCheckBox, setAgeThreeCheckBox] = useState(false);
  const [ageFourCheckBox, setAgeFourCheckBox] = useState(false);
  const [ageFiveCheckBox, setAgeFiveCheckBox] = useState(false);

  const [selectedObjectiveFilters, setSelectedObjectiveFilters] = useState([]);
  const [selectedSpecialityFilter, setSelectedSpecialityFilter] = useState([]);
  const [selectedNoOfTrainingsFilter, setSelectedNoOfTrainingsFilter] =
    useState([]);
  const [selectedExperienceFilter, setSelectedExperienceFilter] = useState([]);
  const [selectedAlterFilter, setSelectedAlterFilter] = useState([]);
  const [isNewCoach, setIsNewCoach] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const handleRemoveFilter = (filterType, item) => {
    switch (filterType) {
      case "Objective":
        if (item?.name === "Abnehmen") setLooseWeightCheckBox(false);
        if (item?.name === "Gesunde Ernährung") setHealtyEatingCheckBox(false);
        if (item?.name === "Muskelaufbau lean") setLeanMuscleCheckBox(false);
        if (item?.name === "Muskelaufbau bulk") setBulkMuscleCheckBox(false);

        const objectiveFilters = getFilteredList(
          selectedObjectiveFilters,
          item?.name
        );
        setSelectedObjectiveFilters(objectiveFilters);

        handleAllFilters({
          selectedObjectiveFilters: objectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        break;
      case "Speciality":
        if (item?.name === "Bodybuilding") setBodyBuildingCheckBox(false);
        if (item?.name === "Fitnessernährung")
          setFitnessNutritionCheckBox(false);
        if (item?.name === "Ausgewogene Ernährung")
          setBalancedNutritionCheckBox(false);
        if (item?.name === "Leistungssport") setCompetitiveSportCheckBox(false);
        if (item?.name === "Vegane Ernährung") setVeganDietCheckBox(false);
        if (item?.name === "Vegetarische Ernährung") setVegetarischeErnährungCheckbox(false);
        if (item?.name === 'Hormonbalance') setHormonbalanceCheckbox(false)
        if (item?.name === 'Ernährung für Senioren') setErnährungfürSeniorenCheckbox(false)
        if (item?.name === 'Kinder- und Jugendernährung') setKinderundJugendernährungCheckbox(false)
        if (item?.name === 'Adipositas-Management') setAdipositasManagementCheckbox(false)
        if (item?.name === 'Gewichtsmanagement bei Frauen') setGewichtsmanagementbeiFrauenCheckbox(false)
        if (item?.name === 'Gewichtsmanagement bei Männern') setGewichtsmanagementbeiMännernCheckbox(false)
        if (item?.name === 'Darmgesundheit') setDarmgesundheitCheckbox(false)
        if (item?.name === 'Ernährung in der Schwangerschaft') setErnährunginderSchwangerschaftCheckbox(false)
        if (item?.name === 'Ernährung in der Menopause') setErnährunginderMenopauseCheckbox(false)

        const specialityFilters = getFilteredList(
          selectedSpecialityFilter,
          item?.name
        );
        setSelectedSpecialityFilter(specialityFilters);

        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter: specialityFilters,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        break;
      case "NoOfTrainings":
        if (item?.name === "1 Ausbildung") setTrainingOneCheckBox(false);
        if (item?.name === "2 Ausbildungen") setTrainingTwoCheckBox(false);
        if (item?.name === "3 Ausbildungen") setTrainingThreeCheckBox(false);
        if (item?.name === "4 Ausbildungen") setTrainingFourCheckBox(false);
        if (item?.name === "5 Ausbildungen")
          setTrainingMoreThanFourCheckBox(false);

        const trainingsFilter = getFilteredList(
          selectedNoOfTrainingsFilter,
          item?.name
        );
        setSelectedNoOfTrainingsFilter(trainingsFilter);

        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter: trainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        break;
      case "Experience":
        // if (item?.name === "Berufsanfänger") setExperienceYoungCheckBox(false);
        if (item?.name === "0 - 2 Jahre") setExperienceOneCheckBox(false);
        if (item?.name === "2 - 7 Jahre") setExperienceTwoCheckBox(false);
        if (item?.name === "> 7 Jahre") setExperienceThreeCheckBox(false);
        // if (item?.name === "4 Jahre") setExperienceFourCheckBox(false);
        // if (item?.name === "5 Jahre") setExperienceFiveCheckBox(false);
        // if (item?.name === "6 Jahre") setExperienceSixCheckBox(false);

        const experienceFilters = getFilteredList(
          selectedExperienceFilter,
          item?.name
        );
        setSelectedExperienceFilter(experienceFilters);

        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter: experienceFilters,
          selectedAlterFilter,
          isNewCoach,
        });

        break;
      case "Alter":
        if (item?.name === "18 - 25 Jahre") setAgeOneCheckBox(false);
        if (item?.name === "26 - 30 Jahre") setAgeTwoCheckBox(false);
        if (item?.name === "30 - 35 Jahre") setAgeThreeCheckBox(false);
        if (item?.name === "35 - 40 Jahre") setAgeFourCheckBox(false);
        if (item?.name === "Uber 40 Jahre") setAgeFiveCheckBox(false);

        const ageFilters = getFilteredList(selectedAlterFilter, item?.name);
        setSelectedAlterFilter(ageFilters);

        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter: ageFilters,
          isNewCoach,
        });

        break;
      default:
        break;
    }
  };

  const handleFilterChange = (filterType, name, checked) => {
    switch (filterType) {
      case "Objective":
        const objectiveFilters = [
          ...getFilteredList(selectedObjectiveFilters, name),
          { name, checked },
        ];
        handleAllFilters({
          selectedObjectiveFilters: objectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        setSelectedObjectiveFilters(objectiveFilters);
        break;
      case "Speciality":
        const specialityFilters = [
          ...getFilteredList(selectedSpecialityFilter, name),
          { name, checked },
        ];
        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter: specialityFilters,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        setSelectedSpecialityFilter(specialityFilters);
        break;
      case "NoOfTrainings":
        const trainingFilters = [
          ...getFilteredList(selectedNoOfTrainingsFilter, name),
          { name, checked },
        ];
        handleAllFilters({
          selectedSpecialityFilter,
          selectedObjectiveFilters,
          selectedNoOfTrainingsFilter: trainingFilters,
          selectedExperienceFilter,
          selectedAlterFilter,
          isNewCoach,
        });
        setSelectedNoOfTrainingsFilter(trainingFilters);
        break;
      case "Experience":
        const experienceFilters = [
          ...getFilteredList(selectedExperienceFilter, name),
          { name, checked },
        ];
        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter: experienceFilters,
          selectedAlterFilter,
          isNewCoach,
        });
        setSelectedExperienceFilter(experienceFilters);
        break;
      case "Alter":
        const alterFilters = [
          ...getFilteredList(selectedAlterFilter, name),
          { name, checked },
        ];

        handleAllFilters({
          selectedObjectiveFilters,
          selectedSpecialityFilter,
          selectedNoOfTrainingsFilter,
          selectedExperienceFilter,
          selectedAlterFilter: alterFilters,
          isNewCoach,
        });
        setSelectedAlterFilter(alterFilters);
        break;
      default:
        break;
    }
  };

  const IsElementExistsInList = (list, element) => {
    const found = list.find((item) => item?.name === element?.name);
    return !!found;
  };

  const getFilteredList = (list, name) => {
    return list?.filter((item) => item?.name !== name);
  };

  const getFilterState = (list, name) => {
    return list?.filter((item) => item?.name === name);
  };

  const renderFilters = (activeList, alwaysOpen) => {
    return (
      <Accordion defaultActiveKey={activeList} alwaysOpen={alwaysOpen}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="border-0 bg-transparent">
            <div className="d-flex justify-content-between w-100">
              <span>Zielsetzung</span>
              <ChevronDown size={26} />
            </div>
          </Accordion.Header>
          <Accordion.Body className="pl-0">
            <Form>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Abnehmen`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Abnehmen"}
                    checked={looseWeightCheckBox}
                    onChange={(e) => {
                      setLooseWeightCheckBox(e.target.checked);
                      handleFilterChange(
                        "Objective",
                        "Abnehmen",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Abnehmen
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-GesundeErnährung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Gesunde Ernährung"}
                    checked={healtyEatingCheckBox}
                    onChange={(e) => {
                      setHealtyEatingCheckBox(e.target.checked);
                      handleFilterChange(
                        "Objective",
                        "Gesunde Ernährung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Gesunde Ernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Muskelaufbaulean`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Muskelaufbau lean"}
                    checked={leanMuscleCheckBox}
                    onChange={(e) => {
                      setLeanMuscleCheckBox(e.target.checked);
                      handleFilterChange(
                        "Objective",
                        "Muskelaufbau lean",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Muskelaufbau lean
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Muskelaufbaubulk`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Muskelaufbau bulk"}
                    checked={bulkMuscleCheckBox}
                    onChange={(e) => {
                      setBulkMuscleCheckBox(e.target.checked);
                      handleFilterChange(
                        "Objective",
                        "Muskelaufbau bulk",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Muskelaufbau bulk
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="border-0 bg-transparent">
            <div className="d-flex justify-content-between w-100">
              <span>Spezialgebiet</span>
              <ChevronDown size={26} />
            </div>
          </Accordion.Header>
          <Accordion.Body className="pl-0">
            <Form>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Bodybuilding`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Bodybuilding"}
                    checked={bodyBuildingCheckBox}
                    onChange={(e) => {
                      setBodyBuildingCheckBox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Bodybuilding",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Bodybuilding
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Fitnessernährung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Fitnessernährung"}
                    checked={fitnessNutritionCheckBox}
                    onChange={(e) => {
                      setFitnessNutritionCheckBox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Fitnessernährung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Fitnessernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Ausgewogene`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Ausgewogene Ernährung"}
                    checked={balancedNutritionCheckBox}
                    onChange={(e) => {
                      setBalancedNutritionCheckBox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Ausgewogene Ernährung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Ausgewogene Ernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Leistungssport`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Leistungssport"}
                    checked={competitiveSupportCheckBox}
                    onChange={(e) => {
                      setCompetitiveSportCheckBox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Leistungssport",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Leistungssport
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-veganDiet`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Vegane Ernährung"}
                    checked={veganDietCheckBox}
                    onChange={(e) => {
                      setVeganDietCheckBox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Vegane Ernährung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Vegane Ernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Vegetarische-Ernährung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Vegetarische Ernährung"}
                    checked={VegetarischeErnährungCheckbox}
                    onChange={(e) => {
                      setVegetarischeErnährungCheckbox(e.target.checked);
                      handleFilterChange(
                        "Speciality",
                        "Vegetarische Ernährung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Vegetarische Ernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Hormonbalance`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Hormonbalance"}
                    checked={HormonbalanceCheckbox}
                    onChange={(e) => {
                      setHormonbalanceCheckbox(e.target.checked)
                      handleFilterChange("Speciality", "Hormonbalance", e.target.checked)
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Hormonbalance
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Ernährung-für-Senioren`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Ernährung für Senioren"}
                    checked={ErnährungfürSeniorenCheckbox}
                    onChange={(e) => {
                      setErnährungfürSeniorenCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Ernährung für Senioren", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Ernährung für Senioren
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Kinder-und-Jugendernährung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Kinder- und Jugendernährung"}
                    checked={KinderundJugendernährungCheckbox}
                    onChange={(e) => {
                      setKinderundJugendernährungCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Kinder- und Jugendernährung", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Kinder- und Jugendernährung
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Adipositas-Management`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Adipositas-Management"}
                    checked={AdipositasManagementCheckbox}
                    onChange={(e) => {
                      setAdipositasManagementCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Adipositas-Management", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Adipositas-Management
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Gewichtsmanagement-bei-Frauen`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Gewichtsmanagement bei Frauen"}
                    checked={GewichtsmanagementbeiFrauenCheckbox}
                    onChange={(e) => {
                      setGewichtsmanagementbeiFrauenCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Gewichtsmanagement bei Frauen", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Gewichtsmanagement bei Frauen
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Gewichtsmanagement-bei-Männern`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Gewichtsmanagement bei Männern"}
                    checked={GewichtsmanagementbeiMännernCheckbox}
                    onChange={(e) => {
                      setGewichtsmanagementbeiMännernCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Gewichtsmanagement bei Männern", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Gewichtsmanagement bei Männern
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Darmgesundheit`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Darmgesundheit"}
                    checked={DarmgesundheitCheckbox}
                    onChange={(e) => {
                      setDarmgesundheitCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Darmgesundheit", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Darmgesundheit
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Ernährung-in-der-Schwangerschaft`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Ernährung in der Schwangerschaft"}
                    checked={ErnährunginderSchwangerschaftCheckbox}
                    onChange={(e) => {
                      setErnährunginderSchwangerschaftCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Ernährung in der Schwangerschaft", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Ernährung in der Schwangerschaft
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Ernährung-in-der-Menopause`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Ernährung in der Menopause"}
                    checked={ErnährunginderMenopauseCheckbox}
                    onChange={(e) => {
                      setErnährunginderMenopauseCheckbox(e.target.checked);
                      handleFilterChange("Speciality", "Ernährung in der Menopause", e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Ernährung in der Menopause
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="d-flex justify-content-between w-100">
              <span>Anzahl der Ausbildungen</span>
              <ChevronDown size={26} />
            </div>
          </Accordion.Header>
          <Accordion.Body className="pl-0 show">
            <Form>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-1-Ausbildung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"1 Ausbildung"}
                    checked={trainingOneCheckBox}
                    onChange={(e) => {
                      setTrainingOneCheckBox(e.target.checked);
                      handleFilterChange(
                        "NoOfTrainings",
                        "1 Ausbildung",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    1 Ausbildung
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-2-Ausbildung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"2 Ausbildungen"}
                    checked={trainingTwoCheckBox}
                    onChange={(e) => {
                      setTrainingTwoCheckBox(e.target.checked);
                      handleFilterChange(
                        "NoOfTrainings",
                        "2 Ausbildungen",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    2 Ausbildungen
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-3-Ausbildung`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"3 Ausbildungen"}
                    checked={trainingThreeCheckBox}
                    onChange={(e) => {
                      setTrainingThreeCheckBox(e.target.checked);
                      handleFilterChange(
                        "NoOfTrainings",
                        "3 Ausbildungen",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    3 Ausbildungen
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-4-Ausbildungen`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"4 Ausbildungen"}
                    checked={trainingFourCheckBox}
                    onChange={(e) => {
                      setTrainingFourCheckBox(e.target.checked);
                      handleFilterChange(
                        "NoOfTrainings",
                        "4 Ausbildungen",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    4 Ausbildungen
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-5-Ausbildungen`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"5 Ausbildungen"}
                    checked={trainingMoreThanFourCheckBox}
                    onChange={(e) => {
                      setTrainingMoreThanFourCheckBox(e.target.checked);
                      handleFilterChange(
                        "NoOfTrainings",
                        "5 Ausbildungen",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    5 Ausbildungen
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="d-flex justify-content-between w-100">
              <span>Berufserfahrung</span>
              <ChevronDown size={26} />
            </div>
          </Accordion.Header>
          <Accordion.Body className="pl-0">
            <Form>
              {/* <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Berufsanfänger`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Berufsanfänger"}
                    checked={experienceYoungCheckBox}
                    onChange={(e) => {
                      setExperienceYoungCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "Berufsanfänger",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Berufsanfänger
                  </Form.Check.Label>
                </Form.Check>
              </div> */}
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-1-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"0 - 2 Jahre"}
                    checked={experienceOneCheckBox}
                    onChange={(e) => {
                      setExperienceOneCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "0 - 2 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    0 - 2 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-2-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"2 - 7 Jahre"}
                    checked={experienceTwoCheckBox}
                    onChange={(e) => {
                      setExperienceTwoCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "2 - 7 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    2 - 7 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-3-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"> 7 Jahre"}
                    checked={experienceThreeCheckBox}
                    onChange={(e) => {
                      setExperienceThreeCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "> 7 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    {`> 7 Jahre`}
                  </Form.Check.Label>
                </Form.Check>
              </div>
              {/* <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-4-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"4 Jahre"}
                    checked={experienceFourCheckBox}
                    onChange={(e) => {
                      setExperienceFourCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "4 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    4 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-5-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"5 Jahre"}
                    checked={experienceFiveCheckBox}
                    onChange={(e) => {
                      setExperienceFiveCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "5 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    {"5 Jahre"}
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-6-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"6 Jahre"}
                    checked={experienceSixCheckBox}
                    onChange={(e) => {
                      setExperienceSixCheckBox(e.target.checked);
                      handleFilterChange(
                        "Experience",
                        "6 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    {"6 Jahre"}
                  </Form.Check.Label>
                </Form.Check>
              </div> */}
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <div className="d-flex justify-content-between w-100">
              <span>Alter</span>
              <ChevronDown size={26} />
            </div>
          </Accordion.Header>
          <Accordion.Body className="pl-0">
            <Form>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-18-25-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"18 - 25 Jahre"}
                    checked={ageOneCheckBox}
                    onChange={(e) => {
                      setAgeOneCheckBox(e.target.checked);
                      handleFilterChange(
                        "Alter",
                        "18 - 25 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    18 - 25 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-26-30-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"26 - 30 Jahre"}
                    checked={ageTwoCheckBox}
                    onChange={(e) => {
                      setAgeTwoCheckBox(e.target.checked);
                      handleFilterChange(
                        "Alter",
                        "26 - 30 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    26 - 30 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-30-35-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"30 - 35 Jahre"}
                    checked={ageThreeCheckBox}
                    onChange={(e) => {
                      setAgeThreeCheckBox(e.target.checked);
                      handleFilterChange(
                        "Alter",
                        "30 - 35 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    30 - 35 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-35-40-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"35 - 40 Jahre"}
                    checked={ageFourCheckBox}
                    onChange={(e) => {
                      setAgeFourCheckBox(e.target.checked);
                      handleFilterChange(
                        "Alter",
                        "35 - 40 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    35 - 40 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-Über-40-Jahre`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    name={"Uber 40 Jahre"}
                    checked={ageFiveCheckBox}
                    onChange={(e) => {
                      setAgeFiveCheckBox(e.target.checked);
                      handleFilterChange(
                        "Alter",
                        "Uber 40 Jahre",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Über 40 Jahre
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  return (
    <>
      <DisplaySelectedFilters
        selectedObjectiveFilters={selectedObjectiveFilters}
        selectedSpecialityFilter={selectedSpecialityFilter}
        selectedNoOfTrainingsFilter={selectedNoOfTrainingsFilter}
        selectedExperienceFilter={selectedExperienceFilter}
        selectedAlterFilter={selectedAlterFilter}
        handleRemoveFilter={handleRemoveFilter}
      >
        <div className="filter-dropdown" ref={ref}>
          <button
            className="filter-btn"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Filter color="#fff" size={18} /> Filter
          </button>
          {isOpen && (
            <div className="filter-dropdown-menu shadow-md bg-white">
              {renderFilters([], false)}
            </div>
          )}
        </div>
      </DisplaySelectedFilters>
      <div className="filters-desktop">
        {renderFilters(["0"], true)}
      </div>
    </>
  );
};

export default CoachSideFilters;

/* <Accordion.Item eventKey="5">
          <Accordion.Body className="pl-0">
            <Form>
              <div className="check-box-row">
                <Form.Check type={"checkbox"} id={`check-api-NeuerCoach`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    checked={isNewCoach}
                    onChange={(e) => {
                      handleAllFilters({
                        selectedSpecialityFilter,
                        selectedNoOfTrainingsFilter,
                        selectedExperienceFilter,
                        selectedAlterFilter,
                        isNewCoach: e.target.checked,
                      });
                      setIsNewCoach(e.target.checked);
                    }}
                  />
                  <Form.Check.Label className="checkbox-label">
                    Neuer Coach
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item> */
