import styled from "styled-components"

export const CoachingPlansBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 50px;
  padding: 30px;
  display: grid;
  @media (max-width: 600px) {
    padding: 0px 24px 30px;
    border-radius: 15px;
  }
`
export const CoachingPlansBoxInner = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 767.99px) {
    /* padding: 25px; */
  }
  /* @media (max-width: 600px) {
    padding: 0px;
  } */
`

export const PlanBoxIcons = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style: none;
  padding-left: 0;
  margin-top: 3rem;
  @media (max-width:600px){
      margin-top: 2rem;
    }
  li {
    display: flex;
    align-items: start;
    margin-bottom: 3rem;
    @media (max-width:600px){
      margin-bottom: 2rem;
    }
  }
  /* &.procedure {
    li {
      align-items: start;
    }
  } */
`

export const PlanBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding: 2rem;
  border-radius: 25px;

  &.active {
    background: #e7faf3;
    h5 {
      color: #2ecf96;
    }
  }
`

export const PlanBoxHead = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  
  border-bottom: 1px solid #142c1f26;
  padding-bottom: 3rem;
  height: 180px;
  @media (max-width: 600px) {
    padding-bottom: 2rem;
    height: auto;
  }
`

export const TitleInfo = styled.h5.attrs({
  className: `${(props) => props.className}`,
})`
  /* font-size: 20px;
  margin-bottom: 20px;
margin-top: 0; */
  color: #7a8780;

  @media (max-width: 600px) {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 40px;
  margin-bottom: 5px;
  margin-top:20px;
  font-weight: 700; */

  @media (max-width: 600px) {
    /* font-size: 24px;
    line-height: 28px; */
  }
`

export const SubTitle = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #a5b5af;
  font-size: 2rem;
  margin-top: 1rem;
  @media (max-width: 600px) {
    /* font-size: 15px;
    line-height: 25px; */
  }
`

export const PlanBoxIconBox = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  width: 40px;
  
  border-radius: 50%;

  img {
    width: 3rem;
    height: 3rem;
  }
`

export const PlanBoxInformation = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  font-size: 13px;
  /* font-size: 16px;
  font-weight: 400; */
  width: calc(100% - 40px);
  /* padding-left: 20px; */
  @media (max-width:600px){
      /* font-size: 15px; */
      /* line-height:23px; */
  }

  /* &.ProcedureTop {
    font-size: 20px !important;
    padding-top: 10px;
  }
  &.StandatProgramText {
    font-size: 20px !important;
    font-weight: 600 !important;
  } */
`


export const PlanLowerBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  height: 380px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 10px 0px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 575px) {
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`
