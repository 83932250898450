import React from "react";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import Chip from "components/chip/Chip";
import * as C from "./ReimburseHealthInsurance.styled";
import { APPEND_CALENDLY_URL } from "constants";
// Images
import Logo1 from "assets/images/App/techniker-krankenkasse-logo.svg";
import Logo2 from "assets/images/App/aok.svg";
import Logo3 from "assets/images/App/DAK-Gesundheit_logo.svg";
import Logo4 from "assets/images/App/barmer_krankenkasse_logo.svg";
import Logo5 from "assets/images/App/ikk-gesundplus.svg";
import Logo6 from "assets/images/App/bkk.svg";
import healthInsuranceImage from "assets/images/health-insurance-bg.svg";

const ReimburseHealthInsurance = ({
  changeOrder = false,
  coach = null,
  onLeadModal,
}) => {
  return (
    <div className="container">
      <div className="container-smaller">
        <C.Wrap
          className={
            changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
          }
        >
          <C.TextContent className="text-content">
            <Chip text="FOODIARY KURS" margin="0 0 30px 0" />
            <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
              Bis zu 100 % Kostenerstattung von deiner Krankenkasse
            </C.Title>
            <C.Description className="ct-text-block color-paragraph font-normal text-base">
              Im Rahmen des Foodiary 12-Wochen Präventionskurses bietet sich dir
              eine strukturierte Lernumgebung mit 12 Einheiten. Jede Einheit
              beinhaltet informative Videos, in denen das Grundlagenwissen zur
              gesunden Ernährung und Prävention vermittelt wird. Die Videos haben
              eine Dauer von 45 bis 60 Minuten und sind praxisbezogen gestaltet, um
              dir das Selbstlernen zu erleichtern.
            </C.Description>
            <br />
            <br />
            <C.Description className="ct-text-block color-dark font-medium text-base mb-4">
              UNTERSTÜTZ DURCH:
            </C.Description>
            <C.InsuranceCompanies>
              <div className="company-item">
                <img src={Logo1} alt="" />
              </div>
              <div className="company-item">
                <img src={Logo2} alt="" />
              </div>
              <div className="company-item">
                <img src={Logo3} alt="" />
              </div>
              <div className="company-item">
                <img src={Logo4} alt="" />
              </div>
              <div className="company-item">
                <img src={Logo5} alt="" />
              </div>
              <div className="company-item">
                <img src={Logo6} alt="" />
              </div>
            </C.InsuranceCompanies>

            <C.Action className="mt-8">
              <button
                className="ct-link-text foodiary-green-button cursor-pointer"
                onClick={() => onLeadModal()}
              >
                Erstgespräch vereinbaren
              </button>
            </C.Action>
          </C.TextContent>
          <C.VisualContent>
            {/* <ReactPlayer
              url={"https://foodiary.app/data/Foodiary-Kurs.mp4"}
              playsinline
              playing
              muted
              loop
            /> */}
            <img src={healthInsuranceImage} alt="" />
          </C.VisualContent>
        </C.Wrap>
      </div>
    </div>
  );
};

export default ReimburseHealthInsurance;
