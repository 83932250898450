import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }
  }
`;

export const TextContent = styled.div``;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 35px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;
export const Description = styled.p`
  color: #44554c;
`;

export const InsuranceCompanies = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .company-item {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 20px #02332a1a;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      width: auto;
      height: auto;
    }
  }
`;

export const VisualContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    justify-content: center;
    img {
      width: 90%;
    }
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
