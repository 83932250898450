import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import Slider from "react-slick";
import Placeholder from "assets/images/placeholder.jpg";
import * as C from "./PromotedCoaches.style";
import SliderCoachCard from "components/SliderCoachCard/SliderCoachCard";

const PromotedCoaches = ({ promotedCoaches, baseUrl }) => {
  const slidesToShow = promotedCoaches?.length === 1 ? 1 : 3;
  console.log("promotedCoaches", promotedCoaches);

  const settings = {
    className: "",
    slidesToShow,
    centerPadding: "20px",
    speed: 500,
    nextArrow: "",
    prevArrow: "",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const renderGreenCard = () => {
    return (
      <div className="scroll-item h-100">
        <div className="card-green item-w h-100">
          <div className="chip bg-black-chip">NEU</div>
          <h4>Unsere neuen Foodiary Coaches</h4>
          <div className="action d-flex justify-content-end">
            <ArrowRightCircle strokeWidth={1} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <C.SliderCard className="generic-slider">
        <Slider className="slick-slider" {...settings}>
          
        </Slider>
      </C.SliderCard> */}
      <div className="green-card-mobile">{renderGreenCard()}</div>
      <section className="scroll-snap-horizontal">
        <div className="green-card-desktop scroll-item">{renderGreenCard()}</div>
        {promotedCoaches?.map((coach, i) => (
          <div className="scroll-item" key={i}>
            <SliderCoachCard
              coach={coach}
              baseUrl={baseUrl}
              key={coach?.id}
              isWidth
            />
          </div>
        ))}
      </section>
    </>
  );
};

export default PromotedCoaches;
