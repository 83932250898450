import * as F from "./comprehensiveSupport.style"
import Icon from "assets/outer-pages/svg/Icon-Ernährung-Foodiary-dark.svg"
import Device from "assets/images/Coaching-pro-offer.svg"
import Ernahrungsplan from "assets/images/ComprehensiveSupport/1-Ernahrungsplan.svg"
import Ernahrungsplane from "assets/images/ComprehensiveSupport/2-EigenerErnahrungscoach.svg"
import ErnahrungsplanerApp from "assets/images/ComprehensiveSupport/3-ErnahrungsplanerApp.svg"
import Rezepte from "assets/images/ComprehensiveSupport/4-rezepte.svg"
import CoachingArea from "assets/images/ComprehensiveSupport/5-Coaching-Area.svg"
import Erfolgsuberprufung from "assets/images/ComprehensiveSupport/6-Erfolgsuberprufung.svg"
import Chip from "components/chip/Chip";

const Items = [
  {
    id: 1,
    title: "Persönlicher Ernährungsplan",
    description: "Gemeinsam ermitteln wir deinen Kalorienbedarf, ermitteln die optimale Ernährungsform und passen dies auf deinen Tagesablauf an.",
    icon: Ernahrungsplan,
    iconHover: "",
  },
  {
    id: 2,
    title: "Eigener Ernährungscoach",
    description: "Deine Ernährungsumstellung wird von einem professionellen Ernährungscoach begleitet. Dieser steht fest an deiner Seite und unterstütz dich tatkräftig.",
    icon: Ernahrungsplane,
    iconHover: "",
  },
  {
    id: 3,
    title: "Moderne Ernährungsplaner-App",
    description: "Mit der App kannst du nicht nur deine Woche vorplanen und eine Einkaufsliste erstellen, sondern auch deine tägliche Kalorienzufuhr überprüfen.",
    icon: ErnahrungsplanerApp,
    iconHover: "",
  },
  {
    id: 4,
    title: "Über 1700 Rezepte",
    description: "Eine große Auswahl an Rezepten erleichtert dir die tägliche Planung. Deine Lieblingsrezepte kannst du einfach selbst erstellen.",
    icon: Rezepte,
    iconHover: "",
  },
  {
    id: 5,
    title: "Foodiary Coaching Bereich",
    description: "Damit deine Ernährungsumstellung ein dauerhafter Erfolg bleibt, erhältst du von uns in der Foodiary Academy über 15 Lernvideos und E-Books.",
    icon: CoachingArea,
    iconHover: "",
  },
  {
    id: 6,
    title: "Wöchentliche Erfolgsüberprüfung",
    description: "In einer wöchentlichen Erfolgskontrolle überprüfen wir deinen Fortschritt. Dein Ernährungscoach analysiert diesen und greift bei Auffälligkeiten ein.",
    icon: Erfolgsuberprufung,
    iconHover: "",
  },
]

const ComprehensiveSupport = () => {
  return (
    <F.Section>
      <div className="">
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <Chip text="Angebot" margin="0 0 10px 0" />
        </div>
        <h2 className="ct-text-block font-light color-dark text-4xl mt-0 mb-20 text-center">
          <strong className="font-semibold">MIT MEINEM FOODIARY COACHING </strong> <br />
          ERHÄLTST DU UMFASSENDE BETREUUNG
        </h2>

        <F.Wrapper className="wrapper d-grid">
          <div>
            <img className="device" src={Device} alt="" />
          </div>
          <F.Services className="services d-grid">
            {Items.map((item, index) => (
              <div className={index === 2 ? 'service active' : 'service'} key={item?.id}>
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={item?.icon} alt="" />
                </div>
                <h6 className="ct-text-block font-medium color-dark text-base mt-8 mb-2">{item?.title}</h6>
                <p className="ct-text-block text-xs color-paragraph mt-2 my-0">{item?.description}</p>
              </div>
            ))}
          </F.Services>
        </F.Wrapper>
      </div>
    </F.Section>
  )
}

export default ComprehensiveSupport
