import React from "react";
import Chip from "components/chip/Chip";
import CoachingPlansPro from "components/CoachingPlansPro/CoachingPlansPro";
// import PriceSlider from "components/PriceSlider/PriceSlider"
import * as F from "./coachFromFoodiaryPro.styled";
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg";
import ActiveUser from "assets/images/myCoaching/Icon-active-user.svg";

const CoachFromFoodiaryPro = ({ coach = null, onLeadModal }) => {
  return (
    <F.CoachingGlanceContainer>
      <F.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 30px 0" />
        <F.GlanceTitle className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Mein Coaching <br /> im Überblick
        </F.GlanceTitle>
        <F.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Während unserem Erstgespräch erstelle ich deinen eigenen
          Ernährungsplan und beantworte deine offenen Fragen. Mit dem Foodiary
          Coaching PRO erhältst du einen persönlichen Ernährungsberater für
          deine 3-monatige Ernährungsumstellung.
        </F.GlanceText>

      <div className="image-badges">
        <F.ImageBadge image={Garantie} />
        <F.ImageBadge image={ActiveUser} />
      </div>
      </F.CoachingGlanceLeft>
      <CoachingPlansPro coach={coach} onLeadModal={onLeadModal} />
    </F.CoachingGlanceContainer>
  );
};

export default CoachFromFoodiaryPro;
