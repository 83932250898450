import { useNavigate } from "react-router-dom";
import * as C from "./CouchCard.style";
import DefaultUserProfile from "assets/outer-pages/png/default.png";

const CouchCard = ({ isMobile, coach, baseUrl }) => {
  const navigate = useNavigate();

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile;

  return (
    <C.CardNew
      className="text-center"
      key={coach?.id}
      onClick={() =>
        navigate(`/coach/${coach?.prename}-${coach?.surname}-${coach?.id}`, {
          state: { coachId: coach?.id },
        })
      }
    >
      <img src={imgUrl} alt="" />
      <h3 className="text-xl">
        {coach?.prename} <br /> {coach?.surname}
      </h3>
      <C.CoachTagBox className="flex-wrap flex-row flex-sm-column">
        <C.CoachTag className="subtitle-desktop">{coach?.subtitle}</C.CoachTag>
        <C.CoachTag className="subtitle-mobile">{coach?.subtitle?.split(' ')[0]}</C.CoachTag>
        <C.CoachTag className="step1">{coach?.step1}</C.CoachTag>
        {/* <C.CoachTag className="more">+1</C.CoachTag> */}
      </C.CoachTagBox>
    </C.CardNew>
    // <Card className="text-center" key={coach?.id}>
    //   <img src={imgUrl} alt="" />
    //   <h3 className="text-xl">
    //     {coach?.prename} {coach?.surname}
    //   </h3>
    //   <CouchItemForMobile>
    //     <p className="text-lg">{coach?.subtitle}</p>
    //   </CouchItemForMobile>
    //   <CouchItem className="text-center">
    //     <small className="text-base">Coach für</small>
    //     <p className="text-lg mb-0">{coach?.subtitle}</p>
    //   </CouchItem>
    //   <CouchStats className="d-flex flex-wrap">
    //     <div className="d-flex flex-column">
    //       <small className="text-base">Spezialgebiet</small>
    //       <p className="text-lg mb-0">{coach?.step1}</p>
    //     </div>
    //     <div className="d-flex flex-column">
    //       <small className="text-base">Coach seit</small>
    //       <p className="text-lg mb-0">{coach?.step2}</p>
    //     </div>
    //     <div className="d-flex flex-column">
    //       <small className="text-base">Ausbildungen</small>
    //       <p className="text-lg mb-0">{coach?.step3}</p>
    //     </div>
    //     <div className="d-flex flex-column">
    //       <small className="text-base">Praxiserfahrung</small>
    //       <p className="text-lg mb-0">{coach?.step4}</p>
    //     </div>
    //   </CouchStats>
    //   <button
    //     className="ct-link-text foodiary-outline-button w-100"
    //     onClick={() =>
    //       navigate(`/coach/${coach?.prename}-${coach?.surname}-${coach?.id}`, {
    //         state: { coachId: coach?.id },
    //       })
    //     }
    //   >
    //     Mehr erfahren
    //   </button>
    // </Card>
  );
};

export default CouchCard;
