import styled from "styled-components";

export const CardNew = styled.div`
  box-shadow: 0px 15px 30px #02332a1a;
  border-radius: 8px;
  padding: 35px 23.5px 30px;
  background: white;
  cursor: pointer;

  @media (max-width: 600px) {
    padding: 15px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    margin: 20px auto;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  > img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 10px solid #f5f5f5;

    @media (max-width: 600px) {
      width: 110px;
      height: 110px;
    }
  }
`;

export const CoachTagBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (max-width: 600px) {
    &.step1 {
      display: none;
    }
  }
`;

export const CoachTag = styled.div`
  background: #f5f5f5;
  border-radius: 16px;
  padding: 8px 12px;

  color: #a5b5af;
  font-size: 13px;
  width: fit-content;

  @media (max-width: 600px) {
    &.step1, &.subtitle-desktop {
      display: none;
    }
  }

  @media (min-width: 601px) {
    &.more, &.subtitle-mobile {
      display: none;
    }
  }

  
`;

export const Card = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  box-shadow: 0px 0px 17px #d6d6d6a2;
  padding: 1.5rem;
  border-radius: 0.4rem;
  background: white;

  @media (max-width: 600px) {
    padding: 1rem 0.5rem;
  }

  h3 {
    font-weight: bold;
    @media (max-width: 600px) {
      /* font-size: 1rem; */
      margin-top: 0.2rem;
      margin-bottom: 0;
    }
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 6px solid #e5e5e540;

    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
    }
  }

  button {
    /* font-size: 1rem; */

    @media (max-width: 600px) {
      width: fit-content;
      /* font-size: 0.9rem; */
      /* padding: 0.6rem 1rem; */
      border-width: 1px;
    }
  }
`;

export const CouchItem = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 0.6rem 0.9rem;

  small {
    color: #a5b5af;
    /* font-size: 1rem; */
  }

  p {
    margin-top: 0.5rem;
    color: #1c9d87;
    /* font-size: 1rem; */
    font-weight: 600;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const CouchItemForMobile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  p {
    font-size: 0.9rem;
    color: #a5b5af;
    margin-top: 0;
    margin-bottom: 0.7rem;
  }

  @media (min-width: 601px) {
    display: none;
  }
`;

export const CouchStats = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  div {
    width: calc(50% - 1px - 1rem);
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;

    small {
      color: #a5b5af;
      /* font-size: 0.86rem; */
    }
    p {
      margin-top: 0.3rem;
      color: #44554c;
      /* font-size: 14px; */
      font-weight: 600;
      min-height: 45px;
      word-break: break-word;
    }
    &:nth-child(2) {
      border-right: none;
    }
    &:nth-child(3) {
      border-bottom: none;
    }
    &:nth-child(4) {
      border-bottom: none;
      border-right: none;
    }
  }

  @media (max-width: 600px) {
    display: none !important;
  }
`;
