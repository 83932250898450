import React from 'react';
import * as F from './chip.styles';

const Chip = (props) => {
    const { margin, id, absolute, color, bg, ...otherProps } = props;
    return (
        <F.ChipWrap margin={margin} style={absolute} background={bg} {...otherProps}>
            <F.Text className='ct-headline py-3 px-6 uppercase text-base rounded-full font-medium color-secondary bg-alt' color={color}>{props.text}</F.Text>
        </F.ChipWrap>
    )
}

export default Chip
