import { useState, useEffect } from "react";
import { useLocation, useSearchParams, useParams, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

import FailureDialog from "components/FailureDialog";
import Header from "components/HeaderExisting/Header";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import Navbar from "components/StickyNavbar/Navbar";
import Footer from "components/BlackFooter/Footer";
import WriteOwnStory from "components/WriteOwnStory/WriteOwnStory";
import ClientsTestimonials from "components/ClientsTestimonials/ClientsTestimonials";
import CouchInfo from "components/CouchInfo/CouchInfo";
import HowItWorks from "components/HowItWorks/HowItWorks";
import CouchServices from "components/CouchServices/CouchServices";
import CouchingSupports from "components/CouchingSupports/CouchingSupports";
import PaymentModal from "modals/PaymentModal/PaymentModal";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import CoachFromFoodiaryPro from "components/CoachFromFoodiaryPro/CoachFromFoodiaryPro";
import * as C from "constants";
import CardSlider from "components/CardSlider/CardSlider";
import ScheduleFirstConsultation from "components/ScheduleFirstConsultation/ScheduleFirstConsultation";
import SectionWrapper from "components/sectionWrapper/SectionWrapper";
import StartWithFoodiary from "components/StartWithFoodiary/StartWithFoodiary";
import ComprehensiveSupport from "components/ComprehensiveSupport/ComprehensiveSupport";
import FreeInitialSupport from "components/FreeInitialSupport/FreeInitialSupport";
import ReimburseHealthInsurance from "components/ReimburseHealthInsurance/ReimburseHealthInsurance";
import CoachInitialSection from "components/CoachInitialSection/CoachInitialSection";

const IMG_BASE_URL = C.IMG_BASE_URL;

function CoachDetails() {
  const { search } = useLocation();
  let { slug } = useParams();
  const navigate = useNavigate()

  const [coachProfile, setCoachProfile] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    getCoachProfile({ id: slug.split("-").at(-1) });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const { success } = queryString.parse(search);
    if (success == "true") {
      setSuccessDialog(true);
    }
    if (success == "false") {
      setCancelDialog(true);
    }
    searchParams.delete("success");
  }, [search]);

  const getCoachProfile = async (payload) => {
    try {
      const res = await axios.post(`${C.BASE_URL}coach/profile`, payload);
      setCoachProfile({
        ...res?.data?.data,
        qualifications: res?.data?.trainer_qualification,
        feedback: res?.data?.coach_feedback
      })
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="couch-page">
        <Navbar onOpenLeadForm={() => setOpenLeadModal(true)} />

        <section className="header-wrap">
          <div className="bg-gray"></div>
          <div className="container">
            <div className="header-details">
              {coachProfile && (
                <CouchInfo
                  coach={coachProfile}
                  baseUrl={IMG_BASE_URL}
                  onPaymentModal={() => setOpenPaymentModal(true)}
                  onLeadModal={() => setOpenLeadModal(true)}
                />
              )}
            </div>
          </div>
        </section>

        <main>
          <div className="container">
            <SectionWrapper mobileMargin="0" margin="160px 0 0">
              {coachProfile && (
                <FreeInitialSupport
                  coach={coachProfile}
                  baseUrl={IMG_BASE_URL}
                  onLeadModal={() => setOpenLeadModal(true)}
                />
              )}
            </SectionWrapper>
          </div>

          <CoachInitialSection coachProfile={coachProfile} />

          <div className="container">

            <SectionWrapper mobileMargin="50px 0 0" margin="130px 0 0">
              <ComprehensiveSupport />
            </SectionWrapper>

            <SectionWrapper mobileMargin="50px 0 0" margin="130px 0 0">
              <ScheduleFirstConsultation
                className="desktopShow"
                coach={coachProfile}
                onLeadModal={() => setOpenLeadModal(true)}
              />
            </SectionWrapper>

          </div>

          <SectionWrapper mobileMargin="50px 0 0" margin="130px 0 0">
            <ReimburseHealthInsurance
              coach={coachProfile}
              onLeadModal={() => setOpenLeadModal(true)}
            />
          </SectionWrapper>

          <div className="container">
            <SectionWrapper mobileMargin="50px 0 0" margin="130px 0 0">
              <CoachFromFoodiaryPro
                coach={coachProfile}
                onLeadModal={() => setOpenLeadModal(true)}
              />
            </SectionWrapper>

            <SectionWrapper mobileMargin="50px 0 0" margin="130px 0 0">
              <TestimonialSlider />
            </SectionWrapper>
          </div>

          <SectionWrapper mobileMargin="50px 0 0" margin="30px 0 0">
            <StartWithFoodiary
              coach={coachProfile}
              onLeadModal={() => setOpenLeadModal(true)}
            />
          </SectionWrapper>

          <PaymentModal
            isOpen={openPaymentModal}
            onClose={() => setOpenPaymentModal(false)}
            couchProfile={coachProfile}
          />

        </main>
      </div>

      <FailureDialog open={cancelDialog} close={() => setCancelDialog(false)} />

      {coachProfile && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coachProfile}
        />
      )}

      <Footer />
    </>
  );
}

export default CoachDetails;
