import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 80px;

  @media(max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
`

export const SectionTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  letter-spacing: 0.8px;
  text-transform: uppercase;
  /* font-size: 40px;
  line-height: 60px;
  font-weight: 700; */
  

  @media(max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media(max-width: 600px) {
   /* font-size: 26px;
   line-height: 32px; */
  }
`

export const SectionTitleMobile = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 40px;
  font-family: "RubikLight";
  font-weight: 300;
  @media (max-width:600px){
    font-size: 24px;
    line-height: 36px;
  }

  b {
    font-family: "RubikBold";
    font-weight: 600;
  }

  @media(min-width: 600.99px) {
    display: none;
  }
`

export const SubInfo = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  color: #44554C;
  font-size: 18px;
  line-height:28px;
  font-family: "RubikRegular";
  max-width: 750px;
  margin: 20px auto 0;
  @media (max-width:600px){
    font-size: 15px;
    line-height: 23px;
  }
`