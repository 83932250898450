import styled from "styled-components"

export const Card = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: #f0f4f3;
  border-radius: 30px;
  position: relative;
  /* padding: 50px 30px 30px;
  @media (max-width:600px){
    padding: 30px 20px;
  } */
`

export const Title = styled.h3`
  /* color: #1c9d87; */
  /* font-size: 22px;
  font-weight: 600;
  
  @media (max-width:600px){
    font-size:19px;
    line-height:30px;
  } */
`

export const Description = styled.p`
  /* color: #44554c;
  font-size: 18px;
  font-weight: 400;
  margin: 30px 0 50px;
  
  @media (max-width:600px){
    font-size:15px;
    line-height:23px;
        margin: 15px 0 50px;
  } */
`

export const IconBox = styled.div`
  background: white;
  /* width: 80px;
  height: 80px; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  
  img {
    width: 30px;
    /* height: 36px; */
    object-fit: contain;
  }
`
