import styled from "styled-components"

const Navbar = styled.nav`
  /* background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
  height: 180px;
  position: relative;

  .navbar-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 700px) {
    height: 250px;
  }

  @media (max-width: 600px) {
    height: 180px;

    .navbar-bg {
      object-fit: cover;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-top: 1.3rem;
  @media (max-width: 700px) {
    padding-top: 5rem;
  }
`

const Links = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: fit-content;

  li {
    padding: 1rem 1.5rem;
    display: inline-block;
    a {
      color: white;
      text-decoration: none;
      font-weight: 600;
      font-size: 20px;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MenuIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 20px;
  top: 23px;

  @media (min-width: 601px) {
    display: none;
  }
`

const Logo = styled.img.attrs((props) => ({
  src: props.src,
  className: `${(props) => props.className}`,
}))`
  width: 8rem;
  position: absolute;
  left: 0;
  top: 70.5%;
  transform: translate(0, -50%);

  @media (max-width: 700px) {
    left: 50%;
    top: 30px;
    transform: translate(-50%, -15px);
  }

  @media (max-width: 600px) {
    left: 50px;
    top: 33px;
    transform: translate(-50%, -15px);
    width: 84px;
    height: 39px;
  }
`

const Container = styled.div`
  max-width: 95%;
  margin: auto;

  @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }
`

export { Navbar, Links, Logo, Container, Wrapper, MenuIcon }
