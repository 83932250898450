import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PopupButton } from "react-calendly";
import * as C from "./navbar.style";

import Accordian from "components/Accordians/Accordian";
import * as CONSTANT from "constants";

// Images
import MenuIcon from "assets/images/menu-icon.svg";
import CrossIcon from "assets/images/cross.svg";
import Logo from "assets/images/Foodiary-Logo.png";
import ArrowCircle from "assets/images/arrow-circle.svg";

const Menu = (title, link, subMenuItems = [], button = null) => (
  <div className="foodiary-menu">
    <span
      className="foodiary-menu-trigger foodiary-menu-title menu-item"
      onClick={() => {
        window.open(link);
      }}
    >
      {title}
    </span>
    {subMenuItems.length > 0 && (
      <div className="foodiary-menu-box">
        <div className="foodiary-menu-box-child">
          {subMenuItems.map((item, i) => (
            <a href={item?.link} key={i} className="menu-item-link menu-item">
              {item?.name}
            </a>
          ))}
          {button && (
            <div className="menu-item-button-wrap">
              <button
                className="ct-link-text foodiary-outline-button w-100"
                onClick={() => {
                  window.open(button?.link, "_blank");
                }}
              >
                {button?.title}
              </button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
const Navbar = (props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [image, setImage] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const onToggle = (question) => {
    setImage(question?.img);
    setActiveQuestion(question);
  };

  useEffect(() => {
    window.addEventListener("scroll", getSticky);
    return () => {
      window.removeEventListener("scroll", getSticky);
    };
  }, []);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMobileMenu]);

  const getSticky = (e) => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  return (
    <>
      <C.Container>
        <C.NavbarWrapper
          className="pl-8 p-5"
          isSticky={isSticky}
          showMobileMenu={showMobileMenu}
        >
          <C.Navbar isSticky={isSticky} showMobileMenu={showMobileMenu}>
            <NavLink to="/" exact="true">
              <C.Logo src={Logo} alt="logo" />
            </NavLink>
            <C.NavItems>
              <li>
                <a
                  href="https://www.foodiary.app"
                  activeclassname="active-nav-link"
                  className="router-nav-link menu-item"
                >
                  Home
                </a>
              </li>
              <li>
                {Menu(
                  CONSTANT.AppMenu.title,
                  CONSTANT.AppMenu.link,
                  CONSTANT.AppMenu.items,
                  CONSTANT.AppMenu.button
                )}
              </li>
              <li>
                {Menu(
                  CONSTANT.CoachMenu.title,
                  CONSTANT.CoachMenu.link,
                  CONSTANT.CoachMenu.items,
                  CONSTANT.CoachMenu.button
                )}
              </li>
              <li>
                {Menu(
                  CONSTANT.HealthInsuranceMenu.title,
                  CONSTANT.HealthInsuranceMenu.link,
                  CONSTANT.HealthInsuranceMenu.items,
                  CONSTANT.HealthInsuranceMenu.button
                )}
              </li>
              <li>
                {Menu(
                  CONSTANT.ToolsAndServicesMenu.title,
                  CONSTANT.ToolsAndServicesMenu.link,
                  CONSTANT.ToolsAndServicesMenu.items
                )}
              </li>
              <li>
                {Menu(
                  CONSTANT.PartnerMenu.title,
                  CONSTANT.PartnerMenu.link,
                  CONSTANT.PartnerMenu.items,
                  CONSTANT.PartnerMenu.button
                )}
              </li>

              <li>
                <button
                  className="ct-link-text foodiary-green-button text-decoration-none"
                  onClick={() => props.onOpenLeadForm()}
                >
                  Termin vereinbaren
                </button>
                {/* <PopupButton
                  className="ct-link-text foodiary-green-button text-decoration-none"
                  url="https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1&text_color=303030&primary_color=1c9d87"
                  rootElement={document.getElementById("root")}
                  text="Termin vereinbaren"
                /> */}
              </li>
              <li>
                <img
                  style={{ width: "29px", cursor: "pointer" }}
                  src={ArrowCircle}
                  alt="arrow"
                  onClick={() => {
                    window.open("https://my.foodiary.app/", "_blank");
                  }}
                />
              </li>
            </C.NavItems>
            {/* <PopupButton
            className="btn-secondary rounded"
            url="https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1&text_color=303030&primary_color=1c9d87"
            rootElement={document.getElementById("root")}
            text="Termin vereinbaren"
          /> */}

            <C.MobileNavItems>
              {!showMobileMenu && (
                <img
                  className="menu-icon"
                  src={MenuIcon}
                  alt="menu"
                  onClick={() => {
                    setShowMobileMenu(!showMobileMenu);
                  }}
                />
              )}
              {showMobileMenu && (
                <img
                  className="cross-icon"
                  src={CrossIcon}
                  alt="cross"
                  onClick={() => {
                    setShowMobileMenu(!showMobileMenu);
                  }}
                />
              )}
              <img
                style={{
                  width: "30px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                src={ArrowCircle}
                alt="arrow"
                onClick={() => {
                  window.open("https://my.foodiary.app/", "_blank");
                }}
              />
            </C.MobileNavItems>
          </C.Navbar>

          {showMobileMenu && (
            <C.MobileNavbar>
              <C.NavbarItemsWrapper>
                <C.MobileNavbarItems>
                  <li>
                    <NavLink
                      to="/"
                      exact="true"
                      activeclassname="active-nav-link"
                      className="router-nav-link menu-item"
                    >
                      Home
                    </NavLink>
                  </li>
                </C.MobileNavbarItems>
                <Accordian
                  data={CONSTANT.NavbarMenusOnMobile}
                  activeQuestion={activeQuestion}
                  onExpanded={(question) => onToggle(question)}
                  titleShouldHaveLink
                />
                <div className="menu-item-button-wrap">
                  <button
                    className="ct-link-text foodiary-outline-button w-100"
                    onClick={props.onOpenLeadForm}
                  >
                    Termin vereinbaren
                  </button>
                </div>
              </C.NavbarItemsWrapper>
            </C.MobileNavbar>
          )}
        </C.NavbarWrapper>
      </C.Container>
    </>
  );
};

export default Navbar;
