import { Link, useNavigate } from "react-router-dom"
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
import Slider from "react-slick"
import moment from "moment"
import { PopupButton } from "react-calendly"
import {
  SliderCard,
  SliderItemCard,
  UserProfile,
  UserProfileForMobile,
  IconActions,
  UserContent,
  UserDescription,
  CouchItem,
  Actions,
} from "./UserSlider.style"
import { APPEND_CALENDLY_URL } from "constants"

import CouchIcon from "assets/outer-pages/svg/Abnehmen-Icon.svg"
import InstagramIcon from "assets/outer-pages/svg/Instagram-Icon.svg"
import MailIcon from "assets/outer-pages/svg/Mail-Icon.svg"
import ShareIcon from "assets/outer-pages/svg/Share-Icon.svg"
import UserProfileImage from "assets/outer-pages/png/user.jpg"

function NextArrow(props) {
  const { className, style, onClick } = props

  return (
    <ArrowRightCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <ArrowLeftCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

const UserSlider = ({ isMobile, promotedCouchList, baseUrl }) => {
  const navigate = useNavigate()

  const settings = {
    className: "",
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 1,
    speed: 500,
    nextArrow: <NextArrow className="disabled" />,
    prevArrow: <PrevArrow className="disabled" />,
  }

  return (
    <>
      <SliderCard className="user-slider">
        <Slider className="slick-slider" {...settings}>
          {promotedCouchList.map((coach) => (
            <div className="slick-item" key={coach?.id}>
              <SliderItemCard className="d-flex gap-5">
                <UserProfile className="d-flex flex-column align-items-center gap-3">
                  {/* <img
                    src={
                      `${baseUrl}${coach?.imgpath}/${coach?.profilePic}.${coach?.imgtype}` ||
                      UserProfileImage
                    }
                    alt="user"
                  /> */}
                  <img
                    src={`${baseUrl}${coach?.profilePic}` || UserProfileImage}
                    alt="user"
                  />
                  <IconActions className="d-flex align-items-center gap-3">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={coach.instagram_url}
                    >
                      <img src={InstagramIcon} alt="user" />
                    </a>
                    <a href={`mailto:${coach.email}`}>
                      <img src={MailIcon} alt="user" />
                    </a>
                    <a target="_blank" rel="noreferrer" href={"#"}>
                      <img src={ShareIcon} alt="user" />
                    </a>
                  </IconActions>
                </UserProfile>
                <UserProfileForMobile className="d-flex gap-3">
                  <img
                    src={`${baseUrl}${coach?.profilePic}` || UserProfileImage}
                    alt="user"
                  />
                  <div className="details">
                    <h2 className="mt-0">
                      {coach.prename} {coach.surname}
                    </h2>
                    <small>
                      {moment().diff(coach.birthday, "years")} Jahre
                    </small>
                    <div className="icons d-flex align-items-center gap-3">
                      <Link to={coach.instagram_url}>
                        <div className="icon">
                          <img src={InstagramIcon} alt="user" />
                        </div>
                      </Link>
                      <Link to={coach.instagram_url}>
                        <div className="icon">
                          <img src={MailIcon} alt="user" />
                        </div>
                      </Link>
                      <Link to={coach.instagram_url}>
                        <div className="icon">
                          <img src={ShareIcon} alt="user" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </UserProfileForMobile>
                <UserContent>
                  <div className="name-details">
                    <h2 className="mt-0">
                      {coach.prename} {coach.surname}
                    </h2>
                    <small>
                      {moment().diff(coach.birthday, "years")} Jahre
                    </small>
                  </div>
                  <CouchItem className="d-flex align-items-center">
                    <img src={CouchIcon} alt="user" />
                    <div>
                      <small>Coach für</small>
                      <p className="mb-0">{coach.subtitle}</p>
                    </div>
                  </CouchItem>
                  <UserDescription>{`"${coach.description}"`}</UserDescription>
                  <Actions className="d-flex gap-3">
                    {/* <a
                      href={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
                      className="btn-primary"
                    >
                      {isMobile ? "Erstgespräch" : "Erstgespräch vereinbaren"}
                    </a> */}
                    <PopupButton
                      className="btn-primary text-decoration-none"
                      url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
                      rootElement={document.getElementById("root")}
                      text={
                        isMobile ? "Erstgespräch" : "Erstgespräch vereinbaren"
                      }
                    />
                    <button
                      className="btn-outline"
                      onClick={() =>
                        navigate(
                          `/coach/${coach?.prename}-${coach?.surname}-${coach?.id}`,
                          { state: { coachId: coach?.id } }
                        )
                      }
                    >
                      {isMobile ? "Coach-Profil" : "Coach-Profil ansehen"}
                    </button>
                  </Actions>
                </UserContent>
              </SliderItemCard>
            </div>
          ))}
        </Slider>
      </SliderCard>
    </>
  )
}

export default UserSlider
