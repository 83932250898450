import React from "react";
import { PopupButton } from "react-calendly";
import StepCard from "components/stepCard/StepCard";
import { APPEND_CALENDLY_URL } from "constants";
// Images
import Peace from "assets/images/peace.svg";
import Thumb from "assets/images/thumbs-up.svg";
import Rock from "assets/images/rock.svg";
import * as F from "./scheduleFirstConsultation.style";

const ScheduleFirstConsultation = ({ coach = null, onLeadModal }) => {
  return (
    <F.Wrap>
      <F.SectionTitle
        id="text_block-307-33"
        className="ct-text-block color-dark text-4xl text-center font-bold"
      >
        Und so einfach funktioniert's
      </F.SectionTitle>
      {/* <F.SectionTitleMobile>
        <b>JETZT DEIN ERSTGESPRÄCH VEREINBAREN!</b>
      </F.SectionTitleMobile> */}
      {/* <F.SubInfo>
        In drei Schritten erfährst du, wie du dein kostenloses Erstgespräch mit deinem Ernährungsberater vereinbarst und du zeitnah mit ihm deine Ernährungsumstellung starten kannst.
      </F.SubInfo> */}
      <F.CardsWrap>
        <StepCard
          title="Vereinbare dein Erstgespräch"
          description="Über meinen Kalender, kannst du dein kostenloses Erstgespräch mit mir vereinbaren. In einem 45-minütigem Zoom-Call lernen wir beide uns unverbindlich kennen."
          icon={Thumb}
        />
        <StepCard
          title="Lerne mich kennen"
          description="Zum vereinbarten Zeitpunkt treffen wir uns im Zoom-Call. Gemeinsam besprechen wir dein Ziel, deine Situation und entwickeln zeitgleich deinen persönlichen Ernährungsplan."
          icon={Peace}
        />
        <StepCard
          title="Starte mit mir durch"
          description="Nach unserem kennenlernen starten wir
          gemeinsam deine Ernährungsumstellung.
          Hierbei helfe ich dir, dass du in den
          kommenden 12 Wochen dein gesetztes Ziel
          erreichst."
          icon={Rock}
        />
      </F.CardsWrap>
      {/* <PopupButton
        className="ct-link-text foodiary-green-button mx-auto mt-20"
        styles={{ width: "auto", display: "block" }}
        url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
        // url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
        rootElement={document.getElementById("root")}
        text="Erstgespräch vereinbaren"
      /> */}

      <button
        className="ct-link-text foodiary-green-button mx-auto mt-20"
        onClick={() => onLeadModal()}
      >
        Erstgespräch vereinbaren
      </button>
    </F.Wrap>
  );
};

export default ScheduleFirstConsultation;
