import React from "react"
import { Link } from "react-router-dom"
import * as F from "./footer.style"

// Images
import Logo from "assets/images/myCoaching/Foodiary-Logo-White.png"
import PlayStore from "assets/images/myCoaching/play-store.png"
import AppStore from "assets/images/myCoaching/app-store.png"
import Instagram from "assets/images/insta.svg"
import Pinterest from "assets/images/pinterest.svg"
import Linkedin from "assets/images/linkedin.svg"

function Footer () {
  return (
    <F.FooterWrap>
      <F.Container className="ct-section-inner-wrap">
        <F.FooterContent>
          <div className="top-footer">
            <F.FooterInfo>
              <Link to="/">
                <F.Logo src={Logo} alt="Footer Logo" />
              </Link>
              <div className="app-logos">
                <a href="https://www.foodiary.app/get-the-app/" className="ct-link hover-float">
                  <img id="image-32-15" className="ct-image ios" src={AppStore} alt="Ios App" />
                </a>
                <a href="https://www.foodiary.app/get-the-app/" className="ct-link hover-float">
                  <img id="image-32-15" className="ct-image google" src={PlayStore} alt="Android App" />
                </a>
              </div>
              <div>
                <p>Smarte Ernährungsplanung und individuelle Betreuung für ein gesundes Leben voller Energie und Selbstbewusstsein.</p>
              </div>
            </F.FooterInfo>
            <F.FooterLinks>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">Foodiary</F.Title>
                <div className="links">
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/app/">App</a>
                  <a className="text-base color-paragraph-alt" href="#">Ernährungskurs</a>
                  <a className="text-base color-paragraph-alt" href="https://mycoach.foodiary.app/">Coach finden</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/tools-services/">Tools & Services</a>
                  <a className="text-base color-paragraph-alt" href="http://create.foodiary.app/" target="_blank">Gutschein einlösen</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/partner/">Partner werden</a>
                </div>
              </F.FooterContentUlSectionInner>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">About</F.Title>
                <div className="links">
                  <a className="text-base color-paragraph-alt" href="#">Über Foodiary</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/partner/coach/">Jobs</a>
                  <a className="text-base color-paragraph-alt" href="#">Presse</a>
                  <a className="text-base color-paragraph-alt" href="#">AGB</a>
                  <a className="text-base color-paragraph-alt" href=" https://foodiary.app/datenschutzerklaerung/">Datenschutz</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/impressum/">Impressum</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/cookie-richtlinie-eu">Cookie Policy (EU)</a>
                </div>
              </F.FooterContentUlSectionInner>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">Service</F.Title>
                <div className="links">
                  <a className="text-base color-paragraph-alt" href="mailto:hello@foodiary.app">{'E-Mail'}</a>
                  <a href="https://www.foodiary.app/faq" rel="noreferrer" className="text-base color-paragraph-alt" >Häufige Fragen</a>
                  <a className="text-base color-paragraph-alt" href="https://us10.list-manage.com/survey?u=2b51ea2b7ea0b0c859eceb433&id=6ba301b5c5" rel="noreferrer">App Feedback</a>
                  <a className="text-base color-paragraph-alt" href="https://www.foodiary.app/family/" rel="noreferrer">Foodiary Family</a>
                </div>
              </F.FooterContentUlSectionInner>
            </F.FooterLinks>
          </div>
          <div className="bottom-footer">
            <div>
              <span className="ct-text-block text-xs pr-12">© {new Date().getFullYear()} Foodiary GmbH. All rights reserved.</span>
            </div>
            <div>
              <div>
                <a className="ct-link-text text-sm color-paragraph-alt" href="https://coach.foodiary.app">Login für Coaches</a>
              </div>
              <div>
                <a className="ct-link-text text-sm color-paragraph-alt" href="#">Mitgliedschaft kündigen</a>
              </div>
              <div className="social">
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="http://instagram.com/foodiary.app">
                  <img src={Instagram} alt="instagram" width="30px" height="30px" />
                </a>
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="https://www.pinterest.de/foodiaryapp/">
                  <img src={Pinterest} alt="pinterest" width="30px" height="30px" />
                </a>
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="https://www.linkedin.com/company/34645628/">
                  <img src={Linkedin} alt="linkedin" width="30px" height="30px" />
                </a>
              </div>
            </div>
          </div>
        </F.FooterContent>
      </F.Container>
    </F.FooterWrap>
  )
}

export default Footer
