import React, { useState } from 'react';
import * as F from './accordian.styled';

import SingleQuestion from './Question';
// Images

const Accordian = ({ data, onExpanded, activeQuestion, titleShouldHaveLink = false }) => {
  const [questions, setQuestions] = useState(data)

  return (
    <F.AccordianContainer>
      {questions.map((question) => (
        <SingleQuestion key={question.id} {...question} button={question?.button} titleShouldHaveLink={titleShouldHaveLink} activeQuestion={activeQuestion} onExpanded={() => onExpanded(question)}/>
      ))}
    </F.AccordianContainer>
  )
}

export default Accordian