// ----------- Staging -----------
// const BASE_URL = "http://3.64.74.74/api/";
// const IMG_BASE_URL = "https://foodiary.s3.eu-central-1.amazonaws.com/";

// ----------- Production -----------
const BASE_URL = "https://app.foodiary.app/api/";
const IMG_BASE_URL =
  "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/";

const APPEND_CALENDLY_URL = "?hide_gdpr_banner=1";

export const API_KEY_DEVELOPMENT =
  "hjod58dh5ghh6XxJAN362EHEGUNfmDxtn3WiRGlKkPe56ez83opUu2jaBsjedqMN1rbvnF8snWIpPalqkLJH7dfuLigaWWmtlK1Hbl6uLrP3gWerT4g2v0x5caJHMxp2";
export const API_KEY_PRODUCTION =
  "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw";

export const AppMenu = {
  title: "App",
  link: "https://foodiary.app/app/",
  button: {
    title: "App herunterladen",
    link: "https://foodiary.app/get-the-app/",
  },
  items: [
    { name: "Funktionen", link: "https://foodiary.app/app/funktionen/" },
    { name: "Preise", link: "https://foodiary.app/app/preise/" },
    {
      name: "Ernährungsplan erstellen",
      link: "http://create.foodiary.app/",
      newTab: true,
    },
  ],
};

export const CoachMenu = {
  title: "Coach",
  link: "https://foodiary.app/coach/",
  button: {
    title: "Coach finden",
    link: "https://mycoach.foodiary.app/",
  },
  items: [
    { name: "Erstgespräch", link: "https://foodiary.app/coach/erstgespraech/" },
    { name: "Coaching PRO", link: "https://foodiary.app/coach/coaching-pro/" },
    { name: "Preise", link: "https://foodiary.app/coach/preise/" },
    {
      name: "Coach finden",
      link: "http://mycoach.foodiary.app/",
      newTab: true,
    },
    { name: "FAQ", link: "https://foodiary.app/faq/" },
  ],
};

export const ToolsAndServicesMenu = {
  title: "Tools & Services",
  link: "https://foodiary.app/tools-services/",
  items: [
    {
      name: "Ernährungsguide",
      link: "https://foodiary.app/tools-services/ernaehrungsguide/",
    },
    {
      name: "Kalorienrechner",
      link: "https://foodiary.app/kalorienrechner/",
      newTab: true,
    },
    {
      name: "Lebensmitteldatenbank",
      link: "https://rezepte.foodiary.app/Lebensmittel",
    },
    { name: "Magazin", link: "https://foodiary.app/tools-services/magazin/" },
    {
      name: "Rezeptdatenbank",
      link: "https://rezepte.foodiary.app/Rezepte",
      newTab: true,
    },
  ],
};

export const PartnerMenu = {
  title: "Partner",
  link: "https://foodiary.app/partner/",
  button: {
    title: "Coach werden",
    link: "https://foodiary.app/partner/coach/",
  },
  items: [
    { name: "Ambassador", link: "https://foodiary.app/partner/ambassador/" },
    { name: "Coach werden", link: "https://foodiary.app/partner/coach/" },
    { name: 'Ressourcen', link: 'https://foodiary.app/ressourcen/' },
    { name: "Affiliate", link: "https://foodiary.app/partner/affiliate/" },
    { name: "Sportanlagen", link: "https://foodiary.app/partner/sportanlagen/" },
  ],
};

export const HealthInsuranceMenu = {
  title: "Krankenkasse",
  link: "https://prevention.foodiary.app/",
  button: {
    title: "Kurs starten",
    link: "https://prevention.foodiary.app/starten/",
  },
  items: [{ name: "FAQ", link: "https://prevention.foodiary.app/faq/" }],
};

export const NavbarMenusOnMobile = [
  {
    id: 1,
    title: AppMenu.title,
    link: AppMenu.link,
    items: AppMenu.items,
  },
  {
    id: 2,
    title: CoachMenu.title,
    link: CoachMenu.link,
    items: CoachMenu.items,
  },
  {
    id: 3,
    title: HealthInsuranceMenu.title,
    link: HealthInsuranceMenu.link,
    items: HealthInsuranceMenu.items,
  },
  {
    id: 4,
    title: ToolsAndServicesMenu.title,
    link: ToolsAndServicesMenu.link,
    items: ToolsAndServicesMenu.items,
  },
  {
    id: 5,
    title: PartnerMenu.title,
    link: PartnerMenu.link,
    items: PartnerMenu.items,
  },
];

const SHOP_MENU = [
  {
    heading: "Protein",
    children: [
      {
        link: "https://foodiary.shop/shop/protein/whey-protein",
        text: "Whey Protein",
      },
      {
        link: "https://foodiary.shop/shop/protein/whey-isolat",
        text: "Whey Isolat",
      },
      {
        link: "https://foodiary.shop/shop/protein/veganes-protein",
        text: "Veganes Protein",
      },
      {
        link: "https://foodiary.shop/shop/protein/mehrkomponenten",
        text: "Mehrkomponenten",
      },
    ],
  },
  {
    heading: "Gesundheit",
    children: [
      {
        link: "https://foodiary.shop/shop/gesundheit-wohlbefinden/einzelvitamine",
        text: "Einzelvitamine",
      },
      {
        link: "https://foodiary.shop/shop/gesundheit-wohlbefinden/mineralstoffe",
        text: "Mineralstoffe",
      },
      {
        link: "https://foodiary.shop/shop/gesundheit-wohlbefinden/multivitamie",
        text: "Multivitamine",
      },
      {
        link: "https://foodiary.shop/shop/gesundheit-wohlbefinden/omega-3",
        text: "Omega 3",
      },
      {
        link: "https://foodiary.shop/shop/gesundheit-wohlbefinden/andere-fette",
        text: "Andere Fette",
      },
    ],
  },
  {
    heading: "Aminosäuren",
    children: [
      { link: "https://foodiary.shop/shop/aminosaeuren/bcaa", text: "BCAA" },
      { link: "https://foodiary.shop/shop/aminosaeuren/eaa", text: "EAA" },
      {
        link: "https://foodiary.shop/shop/aminosaeuren/arginin",
        text: "Arginin",
      },
      {
        link: "https://foodiary.shop/shop/aminosaeuren/glutamin",
        text: "Glutamin",
      },
      {
        link: "https://foodiary.shop/shop/aminosaeuren/beta-alanin",
        text: "Beta-Alanin",
      },
    ],
  },
  {
    heading: "Creatin",
    children: [
      { link: "https://foodiary.shop/shop/creatin/kreatin", text: "Kredite" },
      {
        link: "https://foodiary.shop/shop/creatin/kre-alkalyn",
        text: "Kre Alkalyn",
      },
    ],
  },
  {
    heading: "Trainingsbooster",
    children: [
      {
        link: "https://foodiary.shop/shop/trainingsbooster/pre-workout-booster",
        text: "Pre Workout Booster",
      },
      {
        link: "https://foodiary.shop/shop/trainingsbooster/testo-booster",
        text: "Testo-Booster",
      },
      {
        link: "https://foodiary.shop/shop/trainingsbooster/wachmacher",
        text: "Wachmache",
      },
    ],
  },
  {
    heading: "Gainer",
    children: [
      {
        link: "https://foodiary.shop/shop/gainer/weight-gainer",
        text: "Weight Gainer",
      },
      {
        link: "https://foodiary.shop/shop/gainer/kohlenhydrate",
        text: "Kohlenhydrate",
      },
    ],
  },

  {
    heading: "Snacks & Co",
    children: [
      {
        link: "https://foodiary.shop/shop/riegel-snacks-co./proteinriegel",
        text: "Proteinriegel",
      },
      {
        link: "https://foodiary.shop/shop/riegel-snacks-co./snacks",
        text: "Snack",
      },
      {
        link: "https://foodiary.shop/shop/riegel-snacks-co./getraenke",
        text: "Getränke",
      },
    ],
  },
  {
    heading: "Diät & Abnehmen",
    children: [
      {
        link: "https://foodiary.shop/shop/diaet-abnehmen/fatburner",
        text: "Fatburner",
      },
      {
        link: "https://foodiary.shop/shop/diaet-abnehmen/diaet-lebensmittel",
        text: "Diät Lebensmittel",
      },
      {
        link: "https://foodiary.shop/shop/diaet-abnehmen/backen-kochen",
        text: "Backen & Kochen",
      },
    ],
  },
];

const COACH_MENU = [
  {
    heading: "Foodiary Coach",
    children: [
      { link: "https://foodiary.coach/", text: "Home" },
      {
        link: "https://foodiary.coach/ernaehrungsberatung/",
        text: "Das Coaching",
      },
      { link: "https://foodiary.coach/coaching-basic/", text: "Erstgespräch" },
      { link: "https://foodiary.coach/coaching-pro/", text: "Coaching PRO" },
      { link: "https://foodiary.coach/preise/", text: "Preise & Leistung" },
      // { link: "https://coach.foodiary.app/coach-finden", text: "Coach finden" },
      { link: "https://mycoach.foodiary.app", text: "Coach finden" },
      { link: "https://foodiary.coach/faq/", text: "Häufige Fragen" },
    ],
  },
  {
    heading: "Company",
    children: [
      { link: "https://foodiary.coach/partner/", text: "Für Sportanlagen" },
      {
        link: "https://foodiary.coach/affiliate-partnerprogramm/",
        text: "Affiliate-Programm",
      },
      {
        link: "https://www.foodiary.app/brand-ambassador/",
        text: "Brand Ambassador",
      },
      { link: "https://foodiary.coach/coach-werden/", text: "Coach werden" },
      { link: "https://foodiary.coach/franchise/", text: "Franchisesystem" },
    ],
  },
  {
    heading: "Connect",
    children: [
      { link: "https://www.instagram.com/foodiary.app/", text: "Instagram" },
      {
        link: "https://www.facebook.com/FoodiaryMealplanner/",
        text: "Facebook",
      },
      { link: "https://www.linkedin.com/company/34645628/", text: "LinkedIn" },
      { link: "https://www.pinterest.de/foodiaryapp/", text: "Pinterest" },
    ],
  },
];

const APP_MENU = [
  {
    heading: "Foodiary APP",
    children: [
      { link: "https://www.foodiary.app/", text: "Home" },
      {
        link: "https://www.foodiary.app/ernaehrungsplaner/",
        text: "Warum Foodiary",
      },
      {
        link: "https://www.foodiary.app/ernaehrungsplaner/#Ernaehrungsapp",
        text: "Funktionen",
      },
      {
        link: "https://www.foodiary.app/get-the-app/",
        text: "App herunterladen",
      },
    ],
  },
  {
    heading: "Magazin",
    children: [
      { link: "https://foodiary.app/magazin/", text: "Alle Artikel" },
      { link: "https://www.foodiary.app/tag/rezepte", text: "Rezepte" },
      { link: "https://www.foodiary.app/tag/abnehmen/", text: "Abnehmen" },
      { link: "https://www.foodiary.app/tag/ernaehrung/", text: "Ernährung" },
      { link: "https://www.foodiary.app/tag/gesundheit/", text: "Gesundheit" },
    ],
  },
  {
    heading: "Tools",
    children: [
      {
        link: "https://foodiary.app/kalorienrechner/",
        text: "Kalorienrechner",
      },
      {
        link: "https://www.foodiary.app/rezeptrechner/",
        text: "Rezeptrechner",
      },
    ],
  },
  {
    heading: "Freebies",
    children: [
      {
        link: "https://www.foodiary.app/ernaehrungsplan-erstellen/",
        text: "Ernährungsguide",
      },
      {
        link: "https://www.foodiary.app/mineralstoffe-guide/",
        text: "Mineralstoffguide",
      },
      {
        link: "https://www.foodiary.app/supplementguide/",
        text: "Supplementguide",
      },
      { link: "https://www.foodiary.app/vitaminguide/", text: "Vitaminguide" },
    ],
  },
  {
    heading: "Company",
    children: [
      { link: "https://www.foodiary.app/jobs/", text: "Jobs" },
      { link: "https://www.foodiary.app/presse/", text: "Presse" },
      { link: "https://www.foodiary.app/family/", text: "Foodiary Family" },
      {
        link: "https://www.foodiary.app/brand-ambassador/",
        text: "Brand Ambassador",
      },
      { link: "https://foodiary.coach/coach-werden/", text: "Coach werden" },
      {
        link: "https://us10.list-manage.com/survey?u=2b51ea2b7ea0b0c859eceb433&id=6ba301b5c5",
        text: "Dein Feedback",
      },
    ],
  },
  {
    heading: "Connect",
    children: [
      { link: "https://www.instagram.com/foodiary.app/", text: "Instagram" },
      {
        link: "https://www.facebook.com/FoodiaryMealplanner/",
        text: "Facebook",
      },
      { link: "https://www.linkedin.com/company/34645628/", text: "LinkedIn" },
      { link: "https://www.pinterest.de/foodiaryapp/", text: "Pinterest" },
    ],
  },
];

export {
  SHOP_MENU,
  COACH_MENU,
  APP_MENU,
  IMG_BASE_URL,
  BASE_URL,
  APPEND_CALENDLY_URL,
};
