import styled from "styled-components"

export const Container = styled.div`
  /* max-width: 95%; */
  margin: auto;
  color: white;

  @media (min-width: 992px) {
    /* max-width: 90%; */
  }
  /* @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  } */
`

export const FooterWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background-color: #212121;
  /* padding: 5rem 0 3rem; */
  /* margin-top: 3.8rem; */
  /* @media (max-width: 1199px) {
    padding: 3rem 0;
  } */
`

export const AppLogo = styled.div.attrs((props) => ({
  src: props.src,
}))`

  a {
    text-decoration: none;
    margin-right: 1.2rem;
    @media (max-width: 600px) {
      margin: 0 0.6rem 0.6rem;
    }
    img {
      /* width: 6.5rem; */
      height: 4.5rem;
    }
  }
`

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 17.3rem !important;
  height: auto !important;
  margin-bottom: 4rem;

  @media (max-width: 767.99px) {
    width: 12rem !important;
    margin-bottom: 2rem;
  }
`

export const FooterContent = styled.div`
  .top-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    gap: 20px;

    @media (max-width: 991.99px) {
      grid-template-columns: 1fr;
    }
  }
  
  .bottom-footer {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    span {
      color: rgba(255, 255, 255, 0.7);
    }

    > div:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      text-align: right;
    }

    .social {
      justify-self: flex-end;
      > * {
        margin-left: 10px;
      }
      
      > *:first-child {
        margin-left: 0px;
      }
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 2rem;

      > div:nth-child(2) {
        display: flex;
        text-align: center;

        > div {
          display: none;
        }
      }

      .social {
        display: block !important;
      }
      
      > div:nth-child(1){
        text-align: center;
        width: 100%;
      }
    }
  }
`

export const FooterContentLogoSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  a {
    width: fit-content;
  }
  @media (max-width: 767.99px) {
    align-items: flex-start;
    a {
    display: block;
  }
  }
`

export const FooterContentUlSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr;
  gap: 3.1rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    gap: 1.4rem;
  }
`

export const FooterContentUlSectionInner = styled.div`
`

export const Title = styled.h4.attrs({
  className: `${(props) => props.className}`,
})`
  color: #ffffff;
  /* font-family: "RubikLight"; */
  /* font-size: 1.4rem; */
  font-weight: 400;
  margin: 0 0 1.9rem;

  @media (max-width: 991.99px) {
    /* margin: 0 0 20px; */
  }

  @media (max-width: 600px) {
    /* font-size: 14px; */
  }
`

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .links {
    display: flex;
    flex-direction: column;

    a {
      padding: 5px 0px;
    }
  }
  
  @media (max-width: 991.99px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const FooterInfo = styled.div`
  > * {
    margin-bottom: 20px;
  }
  
  p {
    width: 60%;
  }

  .app-logos {
    display: flex;
    gap: 2rem;
  }
  
  @media (max-width: 991.99px) {
    text-align: center;

    p {
      display: none;
    }

    .app-logos {
      justify-content: center;
    }
  }
`

export const FooterInfoLeft = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  p {
    color: rgba(255,255,255);
    opacity: 0.7;
    /* font-size: 0.9rem; */
    /* font-weight: 300; */
    max-width: 38rem;
    margin-top: 4rem;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

export const FooterRights = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  p {
    color: #FFFFFF;
    opacity: 0.5;
    /* font-size: .8rem; */
    /* font-weight: 400; */
    margin: 0;
  }

  @media (max-width: 767.99px) {
    p {
      margin: 0 0 1.4rem;
    }
  }
`

export const FooterTextOnMobile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  p {
    text-align: center;
    color: rgba(255,255,255);
    opacity: 0.7;
    /* font-size: 0.9rem;
    font-weight: 300; */
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  @media (min-width: 767.99px) {
    display: none;
  }
`

export const FooterInfoRight = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;

  @media (max-width: 767.99px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    order: 1;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  .icons {
        gap: min(max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.8571))), 2.4rem);
  }

  li {
    margin-left: 30px;

    a {
      color: #FFFFFF;
opacity: 0.5;
      /* font-family: "RubikRegular"; */
      /* font-size: .8rem; */
      text-decoration: none;
      &.icon {
        opacity: 1;
      }
    }

    @media (max-width: 1199px) {
      margin-left: 0;
      /* margin-right: 1.9rem; */
      &:last-child {
        margin: 0;
      }
    }

    @media (max-width: 600px) {
      /* margin-right: 1.4rem; */
        &:nth-child(3) {
          width: 100%;
      }
    }
  }
`

export const FooterPrivacyLinks = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  list-style: none;
  margin: 1.9rem 0 0;
  padding-left: 0;
  gap: 2rem;

  @media (max-width: 991.99px) {
    display: none;
  }

  li {
    a {
      color: #ffffff;
      /* font-family: "RubikRegular"; */
      font-size: 1.1rem;
      text-decoration: none;
    }
  }
`
